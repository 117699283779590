import { Action, Reducer } from 'redux'
import { AppThunkAction } from '../'
import { FetchClassAction } from './panes'
import { ClearSchedulesAction, ToggleMissingAction, FetchSchedulesFavoritesAction } from './schedules'
import { FetchStaffFavoritesAction } from './staff'
import agent from '../../agent'
import { history } from '../../store/configureStore'
import { toast } from 'react-toastify'
const _ = require('underscore')

export type CreateModal = {
    open: boolean,
    loading: boolean,
    template: {
        id: number,
        value: string,
        category: string
    },
    options: {
        templates: any,
        facilities: any[],
        funding: any[],
        locations: any[],
        titles: any[],
        instructors: any[]
    },
    data: any,
    newSchedule: {
        locationID?: number,
        instructorID?: number,
        dayOfWeek: number,
        startTime: string,
        endTime: string
    }
}

export interface ToggleDrawerAction { type: 'TOGGLE_DRAWER', tab: string|null }
export interface ToggleDimmerAction { type: 'TOGGLE_DIMMER' }
interface SetTabAction { type: 'SET_DRAWER_TAB', tab: string }
interface FetchHousingAction { type: 'FETCH_HOUSING_OPTS', housing: any[] }
interface FetchCareersAction { type: 'FETCH_CAREERS', careers: any[] }
interface ToggleCreateClassAction { type: 'TOGGLE_CREATE_CLASS' }
interface ClearTemplatesAction { type: 'CLEAR_TEMPLATES' }
interface SelectTemplateAction { type: 'SELECT_TEMPLATE', key: number, value: string, category: string, options: any, facilities: any[], funding: any[] }
interface SearchTemplatesAction { type: 'SEARCH_TEMPLATES', templates: any[] }
interface TemplatesLoadingAction { type: 'SEARCH_TEMPLATES_LOADING', value: string }
interface DataChangeAction { type: 'CLASS_DATA_CHANGE', key: string, value: string }
interface FacilityOptionsAction { type: 'CREATE_FACILITY_OPTIONS', sex: any, locations: any[], titles: any[], instructors: any[] }
interface NewScheduleChangeAction { type: 'CREATE_NEW_SCHEDULE_CHANGE', key: string, value: any }
interface ScheduleChangeAction { type: 'CREATE_SCHEDULE_CHANGE', id: number, key: string, value: any }
interface ScheduleAddAction { type: 'CREATE_SCHEDULE_ADD' }
interface ScheduleRemoveAction { type: 'CREATE_SCHEDULE_REMOVE', id: number }
interface CreateClassAction { type: 'CREATE_CLASS' }
interface UnmountDrawerAction { type: 'UNMOUNT_DRAWER' }

type KnownAction = SetTabAction | FetchCareersAction | FetchStaffFavoritesAction | FetchSchedulesFavoritesAction | FetchHousingAction | ToggleMissingAction | ClearSchedulesAction | FetchClassAction | NewScheduleChangeAction | ScheduleChangeAction | ScheduleAddAction | ScheduleRemoveAction | ToggleDrawerAction | ToggleDimmerAction | ToggleCreateClassAction | ClearTemplatesAction | SelectTemplateAction | SearchTemplatesAction | TemplatesLoadingAction | DataChangeAction | FacilityOptionsAction | CreateClassAction | UnmountDrawerAction

export interface DrawerState {
    open: boolean,
    dimmer: boolean,
    tab: string | null,
    lastTab: string | null,
    createClassM: CreateModal,
    options: {
        housing: any[]
    },
    careers: {
        list: any[]
    }
}

var typingTimeout: any

export const actionCreators = {
    setTab: (tab: string) => ({ type: 'SET_DRAWER_TAB', tab: tab } as SetTabAction),
    toggleDimmer: () => ({ type: 'TOGGLE_DIMMER' } as ToggleDimmerAction),
    toggleCreateClass: () => ({ type: 'TOGGLE_CREATE_CLASS' } as ToggleCreateClassAction),
    clearTemplates: () => ({ type: 'CLEAR_TEMPLATES' } as ClearTemplatesAction),
    removeSchedule: (id: number) => ({ type: 'CREATE_SCHEDULE_REMOVE', id: id } as ScheduleRemoveAction),
    addSchedule: () => ({ type: 'CREATE_SCHEDULE_ADD' } as ScheduleAddAction),
    scheduleChange: (id: number, key: string, value: any) => ({ type: 'CREATE_SCHEDULE_CHANGE', id: id, key: key, value: value } as ScheduleChangeAction),
    newScheduleChange: (key: string, value: any) => ({ type: 'CREATE_NEW_SCHEDULE_CHANGE', key: key, value: value } as NewScheduleChangeAction),
    toggleCreateTemplateClass: (recordID: number, title: string, category: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { templateOptions, facilityData, fundingData } = await agent.Classes.templateOptions(recordID)
        dispatch({ type: 'TOGGLE_CREATE_CLASS' } as ToggleCreateClassAction)
        dispatch({ type: 'SELECT_TEMPLATE', key: recordID, value: title, category: category, options: templateOptions, facilities: facilityData, funding: fundingData } as SelectTemplateAction)
    },
    toggleDrawer: (tab:string|null): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().drawer
        let params = new URLSearchParams()
        if (tab && !state.open) { params.append('drawer', tab) }
        history.replace({ pathname: history.location.pathname, search: params.toString() })
        if (state.open) {
            setTimeout(() => {
                dispatch({ type: 'TOGGLE_DIMMER' } as ToggleDimmerAction)
                dispatch({ type: 'UNMOUNT_DRAWER' } as UnmountDrawerAction)
            }, 1000)
        } else {
            dispatch({ type: 'TOGGLE_DIMMER' } as ToggleDimmerAction)
        }
        dispatch({ type: 'TOGGLE_DRAWER', tab: tab } as ToggleDrawerAction)
    },
    searchTemplates: (value: string): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: 'SEARCH_TEMPLATES_LOADING', value: value } as TemplatesLoadingAction)
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(async () => {
            let results:any = {}
            let params = new URLSearchParams()
            params.append('query', value)
            const { courses } = await agent.Classes.searchTemplates(params)
            courses.forEach((course:any) => {
                results[course.category] = {
                    name: course.category,
                    results: course.templates
                }
            })
            dispatch({ type: 'SEARCH_TEMPLATES', templates: results } as SearchTemplatesAction)
        }, 1500)
    },
    fetchHousing: (): AppThunkAction<KnownAction> => async (dispatch) => {
        const { housingData } = await agent.Data.fetchHousing()
        dispatch({ type: 'FETCH_HOUSING_OPTS', housing: housingData } as FetchHousingAction)
    },
    fetchCareers: (): AppThunkAction<KnownAction> => async (dispatch) => {
        const { careerData } = await agent.Data.fetchCareers()
        dispatch({ type: 'FETCH_CAREERS', careers: careerData } as FetchCareersAction)
    },
    selectTemplate: (data: any): AppThunkAction<KnownAction> => async (dispatch) => {
        const { templateOptions, facilityData, fundingData } = await agent.Classes.templateOptions(data.key)
        dispatch({ type: 'SELECT_TEMPLATE', key: data.key, value: data.title, category: data.category, options: templateOptions, facilities: facilityData, funding: fundingData } as SelectTemplateAction)
    },
    dataChange: (key: string, value: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().drawer
        if (key == 'facilityID') {
            const { facilitySex, locationData, titleData, instructorData } = await agent.Classes.facilityOptions(state.createClassM.template.id, value)
            dispatch({ type: 'CREATE_FACILITY_OPTIONS', sex: facilitySex, locations: locationData, titles: titleData, instructors: instructorData } as FacilityOptionsAction)
        }
        dispatch({ type: 'CLASS_DATA_CHANGE', key: key, value: value } as DataChangeAction)
    },
    createClass: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState()
        const { error, recordID } = await agent.Classes.createClass(state.drawer.createClassM.template.id, state.drawer.createClassM.data)
        if (error == null) {
            let num = 1; if (state.panes.panes[1].open) num = 2
            const { classData, attnDate, scheduleData, hiatusData, attendanceOpts, waitlistOpts, gradeOpts, facilityOpts, locationOpts, titleOpts, instructorOpts, fundingOpts } = await agent.Classes.fetchClass(recordID)
            setTimeout(() => {
                dispatch({ type: 'TOGGLE_DIMMER' } as ToggleDimmerAction)
                dispatch({ type: 'UNMOUNT_DRAWER' } as UnmountDrawerAction)
            }, 1000)
            dispatch({ type: 'CREATE_CLASS' } as CreateClassAction)
            dispatch({ type: 'TOGGLE_DRAWER', tab: state.drawer.tab } as ToggleDrawerAction)
            dispatch({ type: 'FETCH_CLASS', num: num, index: 0, animate: true, class: classData, attnDate: attnDate, schedules: scheduleData, hiatus: hiatusData, attendance: attendanceOpts, waitlist: waitlistOpts, grades: gradeOpts, facilities: facilityOpts, locations: locationOpts, titles: titleOpts, instructors: instructorOpts, funding: fundingOpts } as FetchClassAction)
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    favoriteSchedules: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().schedules
        let params = new URLSearchParams()
        params.append('data', btoa(JSON.stringify(state.data)))
        const { error } = await agent.Admin.favoriteSchedules(params)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Schedules favorited')
            const { schedulesFavorited } = await agent.Data.fetchFavorites()
            dispatch({ type: 'FETCH_SCHEDULES_FAVORITES', favorited: schedulesFavorited } as FetchSchedulesFavoritesAction)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    },
    unfavoriteSchedules: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().schedules
        const { error } = await agent.Admin.unfavoriteSchedules()
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Schedules unfavorited')
            const { schedulesFavorited } = await agent.Data.fetchFavorites()
            dispatch({ type: 'FETCH_SCHEDULES_FAVORITES', favorited: schedulesFavorited } as FetchSchedulesFavoritesAction)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    },
}

export const reducer: Reducer<DrawerState> = (state: DrawerState | undefined, incomingAction: Action): DrawerState => {
    if (state === undefined) {
        return {
            open: false,
            dimmer: false,
            tab: null,
            lastTab: null,
            options: {
                housing: []
            },
            careers: {
                list: []
            },
            createClassM: {
                open: false,
                loading: false,
                template: { id: 0, value: '', category: '' },
                options: {
                    templates: {},
                    facilities: [],
                    funding: [],
                    locations: [],
                    titles: [],
                    instructors: []
                },
                data: {
                    schedules: []
                },
                newSchedule: {
                    dayOfWeek: 0,
                    startTime: '',
                    endTime: ''
                }
            }
        }
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'SET_DRAWER_TAB':
            return { ...state, tab: action.tab, lastTab: action.tab }
        case 'TOGGLE_DRAWER':
            return { ...state, open: !state.open, tab: action.tab, lastTab: action.tab }
        case 'TOGGLE_DIMMER':
            return { ...state, dimmer: !state.dimmer }
        case 'UNMOUNT_DRAWER':
            return { ...state, tab: null }
        case 'TOGGLE_CREATE_CLASS':
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    open: !state.createClassM.open
                }
            }
        case 'CLEAR_TEMPLATES':
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    options: {
                        ...state.createClassM.options,
                        templates: {}
                    }
                }
            }
        case 'SELECT_TEMPLATE':
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    template: {
                        id: action.key,
                        value: action.value,
                        category: action.category
                    },
                    options: {
                        facilities: action.facilities,
                        funding: action.funding,
                        locations: [],
                        titles: [],
                        instructors: [],
                        templates: {}
                    },
                    data: {
                        ...action.options,
                        schedules: []
                    }
                }
            }
        case 'FETCH_HOUSING_OPTS':
            return {
                ...state,
                options: {
                    housing: action.housing
                }
            }
        case 'FETCH_CAREERS':
            return {
                ...state,
                careers: {
                    list: action.careers
                }
            }
        case 'SEARCH_TEMPLATES':
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    loading: false,
                    options: {
                        ...state.createClassM.options,
                        templates: action.templates
                    }
                }
            }
        case 'SEARCH_TEMPLATES_LOADING':
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    loading: true,
                    template: {
                        id: 0,
                        value: action.value,
                        category: ''
                    }
                }
            }
        case 'CLASS_DATA_CHANGE':
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    data: {
                        ...state.createClassM.data,
                        [action.key]: action.value
                    }
                }
            }
        case 'CREATE_FACILITY_OPTIONS':
            var data = state.createClassM.data
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    options: {
                        ...state.createClassM.options,
                        locations: action.locations,
                        titles: action.titles,
                        instructors: action.instructors
                    },
                    data: {
                        ...data,
                        facilitySex: action.sex,
                        instructorID: _.any(action.instructors, (o:any) => o.key == data.instructorID) ? data.instructorID : null,
                        titleID: _.any(action.titles, (o:any) => o.key == data.titleID) ? data.titleID : null,
                        defaultLocationID: _.any(action.locations, (o:any) => o.key == data.defaultLocationID) ? data.defaultLocationID : null,
                        schedules: _.map(data.schedule, (schedule:any) => Object.assign(schedule, {
                            instructorID: _.any(action.instructors, (o:any) => o.key == schedule.instructorID) ? schedule.instructorID : null,
                            locationID: _.any(action.locations, (o:any) => o.key == schedule.locationID) ? schedule.locationID : null
                        }))
                    },
                }
            }
        case 'CREATE_NEW_SCHEDULE_CHANGE':
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    newSchedule: {
                        ...state.createClassM.newSchedule,
                        [action.key]: action.value
                    }
                }
            }
        case 'CREATE_SCHEDULE_CHANGE':
            var data = state.createClassM.data
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    data: {
                        ...data,
                        schedules: _.map(data.schedules, (schedule:any) => schedule.id != action.id ? schedule : Object.assign(schedule, { [action.key]: action.value }))
                    }
                }
            }
        case 'CREATE_SCHEDULE_ADD':
            var data = state.createClassM.data
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    data: {
                        ...data,
                        schedules: _.sortBy(data.schedules.concat([Object.assign(state.createClassM.newSchedule, { id: data.schedules.length + 1 })]), (schedule:any) => schedule.dayOfWeek)
                    },
                    newSchedule: {
                        dayOfWeek: 0,
                        startTime: '',
                        endTime: ''
                    }
                }
            }
        case 'CREATE_SCHEDULE_REMOVE':
            var data = state.createClassM.data
            return {
                ...state,
                createClassM: {
                    ...state.createClassM,
                    data: {
                        ...data,
                        schedules: _.filter(data.schedules, (schedule:any) => schedule.id != action.id)
                    }
                }
            }
        case 'CREATE_CLASS':
            return {
                ...state,
                createClassM: {
                    open: false,
                    loading: false,
                    template: { id: 0, value: '', category: '' },
                    options: {
                        templates: {},
                        facilities: [],
                        funding: [],
                        locations: [],
                        titles: [],
                        instructors: []
                    },
                    data: {
                        schedules: []
                    },
                    newSchedule: {
                        dayOfWeek: 0,
                        startTime: '',
                        endTime: ''
                    }
                }
            }
        default:
            return state
    }
}
