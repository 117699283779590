import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import InmateFilters from './InmateFilters'
import InmateRow from './InmateRow'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Icon,
  Table,
  Dimmer,
  Loader,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type TableProps =
    { open: boolean, loading: boolean, hasMore: boolean, tab: string, sorts: any, filters: InmatesStore.InmateFilters, inmates: InmatesStore.Inmate[] } &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmatesTable extends React.PureComponent<TableProps> {
    private HeaderArrow = (props:any) => {
      var sort = _.find(this.props.sorts, (sort:any) => sort.column == props.orderBy)
      if (sort != null) {
        if (sort.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    public render() {
      const HeaderArrow = this.HeaderArrow
      return (
        <Fragment>
          <InmateFilters />
          <div id='inmates-table' style={{top:this.props.open?200:70}}>
            <Dimmer inverted active={this.props.loading}><Loader inverted content="Loading inmates ..." /></Dimmer>
            <InfiniteScroll
                pageStart={0}
                loadMore={this.props.fetchNextInmates}
                hasMore={!this.props.loading && this.props.hasMore}
                initialLoad={false}
                useWindow={false}
                getScrollParent={() => document.getElementById('inmates-table') }
            >
              <Table compact celled striped style={{borderTop:0,marginTop:0}}>
                <Table.Header>
                  {window.location.host.split(".")[0] == "sms" ? <Table.Row>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',width:'1%',padding:'0 25px' }}></Table.HeaderCell>
                    <Popup content="Click to sort inmates by name" trigger={<Table.HeaderCell width={8} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }} onClick={() => this.props.toggleOrder('name')}><div style={{whiteSpace:'nowrap',height:'1em'}}>Name<HeaderArrow orderBy='name' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by SID" trigger={<Table.HeaderCell width={2} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }} onClick={() => this.props.toggleOrder('sid')}><div style={{whiteSpace:'nowrap',height:'1em'}}>SID<HeaderArrow orderBy='sid' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by facility" trigger={<Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }} onClick={() => this.props.toggleOrder('facility')}><div style={{whiteSpace:'nowrap',height:'1em'}}>Facility<HeaderArrow orderBy='facility' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by age" trigger={<Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }} onClick={() => this.props.toggleOrder('age')}><div style={{whiteSpace:'nowrap',height:'1em'}}>Age<HeaderArrow orderBy='age' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by housing" trigger={<Table.HeaderCell width={1} style={{ position:'sticky',top:0,zIndex:1,borderTop:'1px solid rgba(34,36,38,.15)' }} onClick={() => this.props.toggleOrder('housing')}><div style={{whiteSpace:'nowrap',height:'1em'}}>Housing<HeaderArrow orderBy='housing' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by HS verification" trigger={<Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('highSchool')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Popup content="HS Info Verified" position="top center" trigger={<Icon name="building" />} /><HeaderArrow orderBy='highSchool' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by college verification" trigger={<Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('college')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Popup content="Diploma / HSE Verified" position="top center" trigger={<Icon name="graduation" />} /><HeaderArrow orderBy='college' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by TABE hours" trigger={<Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('tabe')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Popup content={`TABE Hours`} position="top center" trigger={<i className="icon icon-t" />} /><HeaderArrow orderBy='tabe' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by GED readiness" trigger={<Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('ged')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Popup content="Ready for GED" position="top center" trigger={<i className="icon icon-g" />} /><HeaderArrow orderBy='ged' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by HiSET passed" trigger={<Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('hiset')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Popup content="HiSET Passed" position="top center" trigger={<i className="icon icon-h" />} /><HeaderArrow orderBy='hiset' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by RIASEC taken" trigger={<Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('riasec')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Popup content="RIASEC Taken" position="top center" trigger={<i className="icon icon-r" />} /><HeaderArrow orderBy='riasec' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by refusals" trigger={<Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('refused')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Popup content={`Refused to Participate`} position="top center" trigger={<Icon name="ban" />} /><HeaderArrow orderBy='refused' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by pretrials" trigger={<Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('pretrial')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Popup content={`In Pretrial`} position="top center" trigger={<Icon name="gavel" />} /><HeaderArrow orderBy='pretrial' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by flags" trigger={<Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('flagged')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Popup content={`Flagged`} position="top center" trigger={<Icon name="warning sign" />} /><HeaderArrow orderBy='flagged' /></div></Table.HeaderCell>} />
                  </Table.Row> : null}
                  {window.location.host.split(".")[0] == "sas" ? <Table.Row>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',width:'1%',padding:'0 25px' }}></Table.HeaderCell>
                    <Table.HeaderCell width={8} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }} onClick={() => this.props.toggleOrder('name')}><div style={{whiteSpace:'nowrap',height:'1em'}}>Name<HeaderArrow orderBy='name' /></div></Table.HeaderCell>
                    <Table.HeaderCell width={2} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }} onClick={() => this.props.toggleOrder('sid')}><div style={{whiteSpace:'nowrap',height:'1em'}}>SID<HeaderArrow orderBy='sid' /></div></Table.HeaderCell>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }} onClick={() => this.props.toggleOrder('facility')}><div style={{whiteSpace:'nowrap',height:'1em'}}>Facility<HeaderArrow orderBy='facility' /></div></Table.HeaderCell>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }} onClick={() => this.props.toggleOrder('age')}><div style={{whiteSpace:'nowrap',height:'1em'}}>Age<HeaderArrow orderBy='age' /></div></Table.HeaderCell>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,zIndex:1,borderTop:'1px solid rgba(34,36,38,.15)' }} onClick={() => this.props.toggleOrder('housing')}><div style={{whiteSpace:'nowrap',height:'1em'}}>Housing<HeaderArrow orderBy='housing' /></div></Table.HeaderCell>
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('highSchool')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Icon name="building" /><HeaderArrow orderBy='highSchool' /></div></Table.HeaderCell>
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }} onClick={() => this.props.toggleOrder('college')}><div style={{whiteSpace:'nowrap',height:'1em'}}><Icon name="graduation" /><HeaderArrow orderBy='college' /></div></Table.HeaderCell>
                  </Table.Row> : null}
                </Table.Header>
                <Table.Body>
                  {this.props.inmates.map((inmate:any) => (
                    <InmateRow inmate={inmate} key={inmate.recordID} />
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </div>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { open: state.inmates.open, loading: state.inmates.tabs.inmates.loading, hasMore: state.inmates.tabs.inmates.hasMore, filters: state.inmates.filters, sorts: state.inmates.sorts, inmates: state.inmates.tabs.inmates.data, tab: state.drawer.tab } },
  { ...DrawerStore.actionCreators, ...InmatesStore.actionCreators, ...PanesStore.actionCreators }
)(InmatesTable as any)
