import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import OTInputField from './OTInputField'
import OTDateField from './OTDateField'
import OTSelectField from './OTSelectField'
import {
  Container,
  Form,
  Grid,
  Input,
  Select,
  Radio,
  Checkbox,
  TextArea,
  Button,
  Header,
  Divider,
  Icon,
  Table,
  Popup,
  Modal
} from 'semantic-ui-react'
const _ = require('underscore')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, hide: boolean, staff: any } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateInfo extends React.PureComponent<InfoProps> {
    public render() {
      const today = new Date(new Date().toDateString())
      return (
        <Container fluid style={{backgroundColor:(this.props.staff.theme ? this.props.staff.theme.paneBackgroundColor : false)}}>
          <Popup content={`The information in the Intake Section is provided by Offendertrak and is overwritten whenever a new update is sent with the exception of the 'AKA', 'Marital Status', 'Comment' fields.`} position="top left" trigger={
            <Header as='h3' style={{marginTop:20}}>
              Intake
              {false ? <div style={{color:'#ccc',float:'right',fontSize:'0.6em',fontWeight:400}}>Printed by: {this.props.staff.recordID} - {format(new Date(), 'MM/dd/yyyy kk:mm')}</div> : null}
            </Header>
          } />
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Form.Group>
                    <OTInputField
                      label='Last name'
                      placeholder='Last name'
                      column='LastName'
                      value={this.props.inmate.info.lastName}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'lastName', data.value.validate())}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'lastName', data.value.validate())}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTInputField
                      label='First name'
                      placeholder='First name'
                      column='FirstName'
                      value={this.props.inmate.info.firstName}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'firstName', data.value.validate())}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'firstName', data.value.validate())}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTInputField
                      label='Middle/other name'
                      placeholder='Middle/other name'
                      column='OtherName'
                      value={this.props.inmate.info.mi}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'mi', data.value.validate())}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'mi', data.value.validate())}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTInputField
                      label='AKA'
                      placeholder='AKA'
                      column='AKA'
                      value={this.props.inmate.info.aka}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'aka', data.value.validate())}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'aka', data.value.validate())}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTInputField
                      label='Marital status'
                      placeholder='Marital status'
                      column='Marital'
                      options={this.props.inmate.options.maritalStatus}
                      value={this.props.inmate.info.marital}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'marital', data.value as string)}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'marital', data.value as string)}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                    />
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Group>
                    <OTDateField
                      label='Date of Birth'
                      column='DoB'
                      value={this.props.inmate.info.dob}
                      onChange={(date:any) => this.props.editIntakeField(this.props.num, 'dob', date ? format(date, 'MM/dd/yyyy') : null)}
                      onFork={(date:any) => this.props.editIntakeField(this.props.num, 'dob', date ? format(date, 'MM/dd/yyyy') : null)}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      width={16}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTInputField
                      label='SSN'
                      placeholder='SSN'
                      column='SSN'
                      value={this.props.inmate.info.ssn}
                      onChange={(e:any) => this.props.editIntakeField(this.props.num, 'ssn', e.target.value.validate())}
                      onFork={(e:any) => this.props.editIntakeField(this.props.num, 'ssn', e.target.value.validate())}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      num={this.props.num}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTSelectField
                      label='Ethnicity'
                      placeholder='Ethnicity'
                      column='Ethnicity'
                      options={this.props.inmate.options.ethnicities}
                      value={this.props.inmate.info.ethnicity}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'ethnicity', data.value)}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'ethnicity', data.value)}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      width={16}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTSelectField
                      label='CSPR Race'
                      placeholder='CSPR Race'
                      column='Ethnicity'
                      options={this.props.inmate.options.races}
                      value={this.props.inmate.info.race}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'race', data.value)}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'race', data.value)}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      width={16}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Field label='Gender' />
                  </Form.Group>
                  <Form.Group style={{paddingBottom: 7}}>
                    <Form.Field className='transparent'>
                      <Radio
                        label='Male'
                        value='M'
                        name='sex'
                        checked={this.props.inmate.info.sex === 'M'}
                        onChange={(e:any, data:any) => {}}
                        readOnly
                      />
                    </Form.Field>
                    <Form.Field className='transparent'>
                      <Radio
                        label='Female'
                        value='F'
                        name='sex'
                        checked={this.props.inmate.info.sex === 'F'}
                        onChange={(e:any, data:any) => {}}
                        readOnly
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Group>
                    <OTSelectField
                      label='Facility'
                      placeholder='Facility'
                      column='Facility'
                      options={this.props.inmate.options.facilities}
                      value={this.props.inmate.info.facilityID}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'facilityID', data.value as string)}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'facilityID', data.value as string)}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      width={16}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTSelectField
                      label='Housing'
                      placeholder='Housing'
                      column='Housing'
                      options={this.props.inmate.options.housing}
                      value={this.props.inmate.info.housingID || null}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'housingID', data.value as string)}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'housingID', data.value as string)}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      width={16}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTSelectField
                      label='Status'
                      placeholder='Status'
                      column='Status'
                      options={this.props.inmate.options.status}
                      value={this.props.inmate.info.status}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'status', data.value as string)}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'status', data.value as string)}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      width={16}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTSelectField
                      label='Custody status'
                      placeholder='Custody status'
                      column='CustodyStatus'
                      options={this.props.inmate.options.custodyStatus}
                      value={this.props.inmate.info.custodyStatus}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'custodyStatus', data.value)}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'custodyStatus', data.value)}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      width={16}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTSelectField
                      label='Security classification'
                      placeholder='Security classification'
                      column='CustodyLevel'
                      options={this.props.inmate.options.securityClassificationCodes}
                      value={this.props.inmate.info.securityClassificationCode}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'securityClassificationCode', data.value)}
                      onFork={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'securityClassificationCode', data.value)}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      width={16}
                    />
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Group>
                    <OTInputField
                      label='SID'
                      placeholder='SID'
                      column='SID'
                      value={this.props.inmate.info.sid}
                      onChange={(e:any) => this.props.editIntakeField(this.props.num, 'sid', e.target.value.validate())}
                      onFork={(e:any) => this.props.editIntakeField(this.props.num, 'sid', e.target.value.validate())}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      num={this.props.num}
                    />
                  </Form.Group>
                  <Form.Group>
                    <OTDateField
                      label='Tentative Release'
                      column='ScheduledReleaseDate'
                      value={this.props.inmate.info.releaseDate}
                      onChange={(date:any) => this.props.editIntakeField(this.props.num, 'releaseDate', date ? format(date, 'MM/dd/yyyy') : null)}
                      onFork={(date:any) => this.props.editIntakeField(this.props.num, 'releaseDate', date ? format(date, 'MM/dd/yyyy') : null)}
                      className={this.props.print ? 'transparent' : ''}
                      flags={this.props.inmate.info.flags}
                      data={this.props.inmate.info.data}
                      width={16}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Field label='Approval' />
                  </Form.Group>
                  <Form.Group style={{paddingBottom: 7}}>
                    {this.props.staff.admin ? <Form.Field
                      control={Checkbox}
                      label='Approval Required'
                      placeholder='Approval Required'
                      name='approvalRequired'
                      checked={this.props.inmate.info.approvalRequired}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'approvalRequired', data.checked as boolean)}
                    /> : <Form.Field
                      control={Checkbox}
                      label='Approval Required'
                      placeholder='Approval Required'
                      name='approvalRequired'
                      checked={this.props.inmate.info.approvalRequired}
                      onChange={(e:any, data:any) => {}}
                      readOnly
                      className='transparent'
                    />}
                  </Form.Group>
                  <Form.Group>
                    <Form.Field label='Active' />
                  </Form.Group>
                  <Form.Group style={{paddingBottom: 7}}>
                    {this.props.staff.admin ? <Form.Field
                      control={Checkbox}
                      label='Active'
                      placeholder='Active'
                      name='active'
                      checked={this.props.inmate.info.active}
                      onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'active', data.checked as boolean)}
                    /> : <Form.Field
                      control={Checkbox}
                      label='Active'
                      placeholder='Active'
                      name='active'
                      checked={this.props.inmate.info.active}
                      onChange={(e:any, data:any) => {}}
                      readOnly
                      className='transparent'
                    />}
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{paddingTop:0}}>
                <Grid.Column>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Comment</Table.HeaderCell>
                        <Table.HeaderCell>Staff</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.info.additionalNotes ? this.props.inmate.info.additionalNotes.map((note:any, i:number) => (
                        <Table.Row key={i} onClick={() => this.props.openEditInfoNote(this.props.num, note)}>
                          <Table.Cell><div style={{whiteSpace:'normal'}}>{note.content}</div></Table.Cell>
                          <Table.Cell>{note.staffName}</Table.Cell>
                          <Table.Cell>{note.date}</Table.Cell>
                        </Table.Row>
                      )) : null}
                      <Table.Row>
                        <Table.Cell colSpan={2}><Input style={{width:'100%'}} value={this.props.inmate.info.newNote||''} onChange={(e:any, data:any) => this.props.editIntakeField(this.props.num, 'newNote', data.value.validate())} /></Table.Cell>
                        <Table.Cell style={{textAlign:'center'}}><Button positive size='mini' content='Add' style={{width:'100%'}} onClick={_.debounce(() => this.props.addInfoNote(this.props.num), 10000, true)} /></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
              {this.props.print ? null : <Grid.Row style={{paddingTop:0}}>
                <Grid.Column>
                  <Button
                    positive
                    content='Save'
                    onClick={_.debounce(() => this.props.saveIntake(this.props.num), 10000, true)}
                  />
                </Grid.Column>
              </Grid.Row>}
            </Grid>
          </Form>
          {!this.props.print || (this.props.hide ? this.props.inmate.transfers.facilities.length > 0 : true) ? <Fragment>
            <Divider />
            <Popup content={`This information is provided by Offendertrak.`} position="top left" trigger={
              <Header as="h3">
                Facility Entry Dates
                {this.props.staff.admin ? <Button positive circular icon='plus' onClick={() => this.props.toggleFacilityTransfer(this.props.num)} style={{marginLeft:10,marginBottom:10,fontSize:'1em',width:36,height:36,paddingTop:(this.props.print ? 11 : 2),paddingLeft:(this.props.print ? 6 : 0)}} /> : null}
                {false ? <div style={{color:'#ccc',float:'right',fontSize:'0.6em',fontWeight:400,marginTop:14}}>Printed by: {this.props.staff.recordID} - {format(new Date(), 'MM/dd/yyyy kk:mm')}</div> : null}
              </Header>
            } />
            <Table compact celled striped style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Facility</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.inmate.transfers.facilities.map((transfer: any) => (
                  <Table.Row key={transfer.recordID}>
                    <Table.Cell>{transfer.facility}</Table.Cell>
                    <Table.Cell>{transfer.date}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Fragment> : null}
          {!this.props.print || (this.props.hide ? this.props.inmate.transfers.housing.length > 0 : true) ? <Fragment>
            <Divider />
            <Popup content={`This information is provided by Offendertrak.`} position="top left" trigger={
              <Header as="h3">
                Housing Transfer Dates
                {false ? <div style={{color:'#ccc',float:'right',fontSize:'0.6em',fontWeight:400}}>Printed by: {this.props.staff.recordID} - {format(new Date(), 'MM/dd/yyyy kk:mm')}</div> : null}
              </Header>
            } />
            <Table compact celled striped style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Facility</Table.HeaderCell>
                  <Table.HeaderCell>Housing</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.inmate.transfers.housing.map((transfer: any) => (
                  <Table.Row key={transfer.recordID}>
                    <Table.Cell>{transfer.facility}</Table.Cell>
                    <Table.Cell>{transfer.housing}</Table.Cell>
                    <Table.Cell>{transfer.date}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Fragment> : null}
          <Modal
            open={this.props.inmate.modals.facilityTransfer.open}
            onClose={() => this.props.toggleFacilityTransfer(this.props.num)}
            size="small"
          >
            <Modal.Header content='Add Facility Transfer' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                      control={Select}
                      label='Facility'
                      placeholder='Facility'
                      name='facilityID'
                      options={this.props.inmate.options.facilities}
                      value={this.props.inmate.modals.facilityTransfer.data.facilityID || null}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'facilityTransfer', 'facilityID', data.value as string)}
                      width={4}
                      search
                    />
                    <Form.Field
                      control={DatePicker}
                      label='Date'
                      name='date'
                      selected={this.props.inmate.modals.facilityTransfer.data.date ? new Date(this.props.inmate.modals.facilityTransfer.data.date) : ''}
                      onChange={(date:any) => this.props.modalDataChange(this.props.num, 'facilityTransfer', 'date', date ? format(date, 'MM/dd/yyyy') : null)}
                      filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                      width={4}
                    />
                    <Form.Field
                      control={Select}
                      label='Requested By'
                      placeholder='Staff'
                      name='staffID'
                      options={this.props.inmate.options.staff}
                      value={this.props.inmate.modals.facilityTransfer.data.staffID || null}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'facilityTransfer', 'staffID', data.value as string)}
                      width={8}
                      search
                    />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleFacilityTransfer(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.addFacilityTransfer(this.props.num), 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editInfoNote.open}
            onClose={() => this.props.toggleEditInfoNote(this.props.num)}
            size='tiny'
          >
            <Modal.Header content='Edit Comment' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    name='content'
                    value={this.props.inmate.modals.editInfoNote.data.content || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editInfoNote', 'content', data.value)}
                    rows={3}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditInfoNote(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeInfoNote(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editInfoNote(this.props.num), 10000, true)}>Edit</Button>
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print, hide: ownProps.hide, staff: state.staff.staff } },
  PanesStore.actionCreators
)(InmateInfo as any)
