import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import InfiniteScroll from 'react-infinite-scroller'
import LogFlagsRow from './LogFlagsRow'
import {
  Icon,
  Table,
  Dimmer,
  Loader,
  Form,
  Input,
  Select
} from 'semantic-ui-react'

type LogProps =
    { logs: any[], filters: any, loading: boolean, hasMore: boolean } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class LogFlags extends React.PureComponent<LogProps> {
    private HeaderArrow = (props:any) => {
      if (this.props.filters.orderBy == props.orderBy) {
        if (this.props.filters.orderDir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    public componentDidMount() {
        this.props.fetchLogFlags()
    }

    public render() {
      const HeaderArrow = this.HeaderArrow
      return (
        <Fragment>
          <div id='filters'>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Search'
                  placeholder='Search...'
                  value={this.props.filters.search}
                  onChange={(e:any, data:any) => this.props.setFlagsFilter('search', data.value.validate())}
                  width={4}
                />
                <Form.Field
                  control={Select}
                  label='Flag'
                  options={[{key:0,value:'all',text:'All'},{key:1,value:'sid',text:'SID'},{key:2,value:'ssn',text:'SSN'},{key:3,value:'sex',text:'Sex'},{key:4,value:'dob',text:'DoB'},{key:5,value:'admission',text:'Admission Date'},{key:6,value:'release',text:'Release Date'},{key:7,value:'race',text:'Race'},{key:8,value:'ethnicity',text:'Ethnicity'},{key:9,value:'lastName',text:'Last Name'},{key:10,value:'firstName',text:'First Name'},{key:11,value:'mi',text:'Middle Name'},{key:12,value:'facility',text:'Facility'},{key:13,value:'photoID',text:'PhotoID'},{key:14,value:'status',text:'Custody Status'},{key:15,value:'level',text:'Custody Level'}]}
                  value={this.props.filters.flag}
                  onChange={(e:any, data:any) => this.props.setFlagsFilter('flag', data.value.validate())}
                  width={2}
                />
                <Form.Field
                  control={Select}
                  label='Resolved'
                  options={[{key:0,value:'N',text:'No'},{key:1,value:'Y',text:'Yes'},{key:2,value:'A',text:'All'}]}
                  value={this.props.filters.resolved}
                  onChange={(e:any, data:any) => this.props.setFlagsFilter('resolved', data.value)}
                  width={3}
                />
                <Form.Field
                  control={Select}
                  label='Active'
                  options={[{key:0,value:'all',text:'All inmates'},{key:1,value:'active',text:'Active inmates'},{key:2,value:'education',text:'Inmates in education'}]}
                  value={this.props.filters.active}
                  onChange={(e:any, data:any) => this.props.setFlagsFilter('active', data.value.validate())}
                  width={3}
                />
              </Form.Group>
            </Form>
          </div>
          <div id='flags-table' style={{height:700,overflowY:'scroll'}}>
            <Dimmer inverted active={this.props.loading}><Loader inverted content="Loading flags ..." /></Dimmer>
            <InfiniteScroll
                pageStart={0}
                loadMore={this.props.fetchNextLogFlags}
                hasMore={!this.props.loading && this.props.hasMore}
                initialLoad={false}
                useWindow={false}
                getScrollParent={() => document.getElementById('flags-table') }
            >
              <Table compact celled striped style={{borderTop:0,marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Record ID</Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.props.toggleFlagOrder('inmate')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Inmate<HeaderArrow orderBy='inmate' /></div></Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.props.toggleFlagOrder('flag')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Flag<HeaderArrow orderBy='flag' /></div></Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.props.toggleFlagOrder('timestamp')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Timestamp<HeaderArrow orderBy='timestamp' /></div></Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.props.toggleFlagOrder('fileName')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>OffenderTrak<HeaderArrow orderBy='fileName' /></div></Table.HeaderCell>
                    <Table.HeaderCell onClick={() => this.props.toggleFlagOrder('resolved')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Resolved<HeaderArrow orderBy='resolved' /></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.logs.map((log:any) => (
                    <LogFlagsRow log={log} key={log.recordID} />
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </div>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { logs: state.admin.logs.flags.data, filters: state.admin.logs.flags.filters, loading: state.admin.logs.flags.loading, hasMore: state.admin.logs.flags.hasMore } },
  AdminStore.actionCreators
)(LogFlags as any)
