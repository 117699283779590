import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as PanesStore from '../../store/reducers/panes'
import * as DrawerStore from '../../store/reducers/drawer'
import ProgramInfo from './ProgramInfo'
import {
  Container,
  Search,
  Button,
  List,
  Modal,
  Form,
  Input,
  Select,
  TextArea,
  Checkbox,
  Label,
  Tab,
  Popup,
  Icon,
  Grid,
  Header,
  Table
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { careers: any, tab: string, mode: string } &
    typeof AdminStore.actionCreators &
    typeof PanesStore.actionCreators &
    typeof DrawerStore.actionCreators &
    WithRouterProps<{}>

class Careers extends React.PureComponent<StaffProps> {
    public componentDidMount() {
        this.props.fetchCareers()
    }

    public render() {
      return (
        <Container fluid style={{overflowY:'auto',height:740,paddingRight:5}}>
          <Grid>
            <Grid.Column width={16}>
              <Header size='large'>Career Pathways</Header>
              <Table compact celled striped style={{ marginTop: "1em" }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Code</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.careers.list.map((career:any, i:number) => (
                    <Table.Row key={career.recordID}>
                      <Table.Cell>
                        {this.props.mode == 'dual' ? <Popup
                          hideOnScroll
                          on='click'
                          trigger={<div className='link' style={{whiteSpace:'normal'}}>{career.name}</div>}
                        >
                          <Popup.Header>Open in pane</Popup.Header>
                          <Popup.Content>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchCareer(1, career.recordID) }}>1</Label>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchCareer(2, career.recordID) }}>2</Label>
                          </Popup.Content>
                        </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchCareer(this.props.mode == 'left' ? 1 : 2, career.recordID) }}>{career.name}</div>}
                      </Table.Cell>
                      <Table.Cell>{career.code}</Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell><Input value={this.props.careers.new.name} name="name" placeholder="New Career Name" onChange={(e:any, data:any) => this.props.changeNewCareer('name', data.value.validate())} style={{width:'100%'}} /></Table.Cell>
                    <Table.Cell style={{textAlign:'center'}}><Button positive content="+" size="mini" onClick={_.debounce(this.props.addCareer, 10000, true)} style={{width:'100%'}} /></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { careers: state.admin.careers, tab: state.drawer.tab, mode: state.panes.mode } },
  { ...AdminStore.actionCreators, ...PanesStore.actionCreators, ...DrawerStore.actionCreators }
)(Careers as any)