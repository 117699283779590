import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format, addDays, addYears } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Icon,
  Form,
  Button,
  Dropdown,
  Input,
  Select,
  TextArea,
  Grid,
  Header,
  Message,
  Divider,
  Search,
  Label
} from 'semantic-ui-react'
const _ = require('underscore')

type PaneProps =
    { career: PanesStore.Career, num: 1 | 2, print: boolean, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class CareerPane extends React.PureComponent<PaneProps> {
    public render() {
        const displayTrait = (trait:any) => {
            var display = ''
            switch (trait.field) {
            case 'dob':
              if (trait.startDate == trait.endDate) {
                  return `DoB: ${trait.startDate}`
              } else {
                  return `DoB: ${trait.startDate} - ${trait.endDate}`
              }
            case 'age':
              if (trait.min == trait.max) {
                  return `Age: ${trait.min}`
              } else {
                  return `Ages: ${trait.min} - ${trait.max}`
              }
            case 'sex':
                var values = _.map(trait.value, (key:string) => _.find(sexOpts, (opt:any) => opt.value == key).text)
                if (values.length > 1) {
                    return `Sexes: ${values.join(', ')}`
                } else {
                    return `Sex: ${values.join(', ')}`
                }
            case 'race':
                var values = _.map(trait.value, (key:string) => _.find(raceOpts, (opt:any) => opt.value == key).text)
                if (values.length > 1) {
                    return `Races: ${values.join(', ')}`
                } else {
                    return `Race: ${values.join(', ')}`
                }
            case 'facility':
                var values = _.map(trait.value, (key:string) => _.find(facilityOpts, (opt:any) => opt.value == key).text)
                if (values.length > 1) {
                    return `Facilities: ${values.join(', ')}`
                } else {
                    return `Facility: ${values.join(', ')}`
                }
            }
            return display
        }

        const sexOpts = [{ key:0,value:'M',text:'Male' },{ key:1,value:'F',text:'Female' }]
        const raceOpts = [{ key:0,value:'I',text:'American Indian or Alaskan Native' },{ key:1,value:'A',text:'Asian' },{ key:2,value:'B',text:'Black or African-American' },{ key:3,value:'P',text:'Hawaiian or Pacific Islander' },{ key:4,value:'H',text:'Hispanic' },{ key:5,value:'W',text:'White' },{ key:6,value:'U',text:'Other or Unknown' }]
        const facilityOpts = [{ key:0,value:'AZSC',text:'AZSC' },{ key:1,value:'HCF',text:'HCF' },{ key:2,value:'HCCC',text:'HCCC' },{ key:3,value:'KCCC',text:'KCCC' },{ key:4,value:'KCF',text:'KCF' },{ key:5,value:'MCCC',text:'MCCC' },{ key:6,value:'OCCC',text:'OCCC' },{ key:7,value:'OOS',text:'OOS' },{ key:8,value:'WCF',text:'WCF' },{ key:9,value:'WCCC',text:'WCCC' }]

        return (
          <Container fluid>
            {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
              <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
            </div>}
            <Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em',marginTop:15}} />
            <Grid style={{marginBottom:10}}>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                  <Header as='h2' style={{display:'inline-block',marginTop:0,width:750,whiteSpace:'normal'}}><div><Icon name='suitcase' /> {this.props.career.data.name}</div></Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Header as='h3'>Info</Header>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Name'
                  value={this.props.career.info.name||''}
                  onChange={(e:any, data:any) => this.props.editCareerField(this.props.num, 'name', data.value.validate())}
                  width={12}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Select}
                  label='Code'
                  className={this.props.print ? 'transparent' : ''}
                  width={4}
                  value={this.props.career.info.code ? this.props.career.info.code.split('') : []}
                  onChange={(e:any, d:any) => {
                    if (d.value.length <= 3) {
                      this.props.editCareerField(this.props.num, 'code', d.value.sort().join(''))
                    }
                  }}
                  options={[
                    { key: 'R', text: 'R', value: 'R' },
                    { key: 'I', text: 'I', value: 'I' }, 
                    { key: 'A', text: 'A', value: 'A' },
                    { key: 'S', text: 'S', value: 'S' },
                    { key: 'E', text: 'E', value: 'E' },
                    { key: 'C', text: 'C', value: 'C' }
                  ]}
                  multiple
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={TextArea}
                  label='Narrative'
                  value={this.props.career.info.narrative||''}
                  onChange={(e:any, data:any) => this.props.editCareerField(this.props.num, 'narrative', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                  width={16}
                  rows={3}
                />
              </Form.Group>
              <Grid columns={2} style={{marginTop:10}}>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h3'>Prerequisites</Header>
                    <Form.Field
                      control={Select}
                      placeholder='Classes'
                      options={_.map(this.props.career.options.templates, (template:any) => { return { ...template, content: <Fragment><div style={{padding:'.78571429rem 1.14285714rem',backgroundColor:(_.any(_.filter(this.props.career.info.templates, (template:any) => template.pre), (t:any) => t.recordID == template.key) ? '#fffaf3' : 'inherit')}}><span className='text' style={{display:'block'}}>{template.text}</span><span className='description' style={{display:'block',float:'none',color:'rgba(0,0,0,.4)',margin:0,marginTop:4}}>{template.category}</span></div></Fragment> } })}
                      value={null}
                      onChange={(e:any, data:any) => this.props.selectCareer(this.props.num, {key: data.value, text: data.options.find((opt:any) => opt.value === data.value).text}, true)}
                      className='nopad'
                      search
                    />
                    <div className='career-labels' style={{marginTop:10,marginBottom:20,whiteSpace:'normal'}}>
                      {_.map(_.filter(this.props.career.info.templates, (template:any) => template.pre), (template:any, t:number) => <Label key={t} style={{margin:0,marginRight:4,marginBottom:4}} onClick={() => this.props.removeTemplate(this.props.num, template.recordID)}>
                        {template.name}
                        <Icon name='delete' onClick={() => this.props.removeTemplate(this.props.num, template.recordID)} />
                      </Label>)}
                    </div>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        placeholder='Traits'
                        options={[{ key:'',value:null,text:'' },{ key: 0, text: 'DoB', value: 'dob' },{ key: 1, text: 'Age', value: 'age' },{ key: 2, text: 'Sex', value: 'sex' },{ key: 3, text: 'Race', value: 'race' },{ key: 4, text: 'Facility', value: 'facility' }]}
                        value={this.props.career.trait.field}
                        onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'field', data.value as string)}
                        width={4}
                      />
                      {['age'].includes(this.props.career.trait.field) ? <Fragment>
                        <Form.Field
                          placeholder="From"
                          control={Select}
                          options={[ { key:'',value:null,text:'' }, ...Array.from({length: 100}, (_, i) => ({ key: i+1, value: i+1, text: (i+1).toString() })) ]}
                          value={this.props.career.trait.min}
                          onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'min', data.value as number)}
                          width={4}
                        />
                        <Form.Field
                          placeholder="To"
                          control={Select}
                          options={[ { key:'',value:null,text:'' }, ...Array.from({length: 100}, (_, i) => ({ key: i+1, value: i+1, text: (i+1).toString() })) ]}
                          value={this.props.career.trait.max}
                          onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'max', data.value as number)}
                          width={4}
                        />
                      </Fragment> : null}
                      {['sex','race','facility'].includes(this.props.career.trait.field) ? <Fragment>
                        <Form.Field
                          control={Select}
                          options={this.props.career.trait.field == 'sex' ? sexOpts : this.props.career.trait.field == 'race' ? raceOpts : this.props.career.trait.field == 'facility' ? facilityOpts : []}
                          value={this.props.career.trait.value ?? []}
                          onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'value', data.value)}
                          width={8}
                          multiple
                        />
                      </Fragment> : null}
                      {['dob'].includes(this.props.career.trait.field) ? <Fragment>
                        <Form.Field
                          placeholder="From"
                          control={DatePicker}
                          selected={this.props.career.trait.startDate ? new Date(this.props.career.trait.startDate) : null}
                          onChange={(date:any) => this.props.editCareerTrait(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        />
                        <Form.Field
                          placeholder="To"
                          control={DatePicker}
                          selected={this.props.career.trait.endDate ? new Date(this.props.career.trait.endDate) : null}
                          onChange={(date:any) => this.props.editCareerTrait(this.props.num, 'endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        />
                      </Fragment> : null}
                      {this.props.career.trait.field ? <Button icon='plus' color='green' circular style={{marginLeft:10,height:32}} onClick={() => this.props.addCareerTrait(this.props.num)} /> : null}
                    </Form.Group>
                    <div className='career-labels' style={{marginTop:10,whiteSpace:'normal'}}>
                      {_.map(this.props.career.info.traits, (trait:any, t:number) => <Label key={t} style={{margin:0,marginRight:4,marginBottom:4}}>
                        {displayTrait(trait)}
                        <Icon name='delete' onClick={() => this.props.removeTrait(this.props.num, trait.field)} />
                      </Label>)}
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h3'>Path</Header>
                    <Form.Field
                      control={Select}
                      placeholder='Classes'
                      options={_.map(this.props.career.options.templates, (template:any) => { return { ...template, content: <Fragment><div style={{padding:'.78571429rem 1.14285714rem',backgroundColor:(_.any(_.filter(this.props.career.info.templates, (template:any) => !template.pre), (t:any) => t.recordID == template.key) ? '#fffaf3' : 'inherit')}}><span className='text' style={{display:'block'}}>{template.text}</span><span className='description' style={{display:'block',float:'none',color:'rgba(0,0,0,.4)',margin:0,marginTop:4}}>{template.category}</span></div></Fragment> } })}
                      value={null}
                      onChange={(e:any, data:any) => this.props.selectCareer(this.props.num, {key: data.value, text: data.options.find((opt:any) => opt.value === data.value).text}, false)}
                      className='nopad'
                      search
                    />
                    <div className='career-labels' style={{marginTop:10,whiteSpace:'normal'}}>
                      {_.map(_.filter(this.props.career.info.templates, (template:any) => !template.pre), (template:any, t:number) => <Label key={t} style={{margin:0,marginRight:4,marginBottom:4}}>
                        {template.name}
                        <Icon name='delete' onClick={() => this.props.removeTemplate(this.props.num, template.recordID)} />
                      </Label>)}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider />
              {this.props.print ? null : <Button positive content='Save' onClick={_.debounce(() => this.props.saveCareer(this.props.num, 'career'), 10000, true)} />}
              {this.props.print ? null : <Button negative content='Remove' onClick={_.debounce(() => this.props.removeCareer(this.props.num), 10000, true)} />}
            </Form>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { career: ownProps.career, num: ownProps.num, print: ownProps.options.print, mode: state.panes.mode } },
  PanesStore.actionCreators
)(CareerPane as any)