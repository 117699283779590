import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PaneStore from '../../store/reducers/panes'
import * as DrawerStore from '../../store/reducers/drawer'
import {
  Icon,
  Header,
  Button,
  Label,
  Table,
  Tab,
  Popup
} from 'semantic-ui-react'

type DrawerProps =
    { mode: string } &
    DrawerStore.DrawerState &
    typeof DrawerStore.actionCreators &
    typeof PaneStore.actionCreators &
    WithRouterProps<{}>

class Careers extends React.PureComponent<DrawerProps> {
    public componentDidMount() {
      this.props.fetchCareers()
    }

    public render() {
      return (
        <Fragment>
          <Button circular icon='arrow left' onClick={() => this.props.toggleDrawer(this.props.tab)} style={{position:'absolute',top:20,right:20}} />
          <Header as='h2' style={{display:'inline-block'}}>
            <Icon name='suitcase' /> Career Pathways
          </Header>
          <Table compact celled striped style={{ marginTop: "1em" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                <Table.HeaderCell width={1}>Code</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.careers.list.map((career:any, i:number) => (
                <Table.Row key={career.recordID}>
                  <Table.Cell>
                    {this.props.mode == 'dual' ? <Popup
                      hideOnScroll
                      on='click'
                      trigger={<div className='link' style={{whiteSpace:'normal'}}>{career.name}</div>}
                    >
                      <Popup.Header>Open in pane</Popup.Header>
                      <Popup.Content>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchCareer(1, career.recordID, 'pathway') }}>1</Label>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchCareer(2, career.recordID, 'pathway') }}>2</Label>
                      </Popup.Content>
                    </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchCareer(this.props.mode == 'left' ? 1 : 2, career.recordID) }}>{career.name}</div>}
                  </Table.Cell>
                  <Table.Cell>{career.code}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.drawer, mode: state.panes.mode } },
  { ...DrawerStore.actionCreators, ...PaneStore.actionCreators }
)(Careers as any)