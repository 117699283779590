import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import {
  Icon,
  Table,
  Popup,
  Label,
  Select,
  Form
} from 'semantic-ui-react'
const _ = require('underscore')

type InmateProps =
    { inmate: InmatesStore.Inmate, housing: any[], tab: string, mode: string } &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateRow extends React.PureComponent<InmateProps> {
    public render() {
      return (
        <Table.Row>
          <Table.Cell style={{textAlign:'center',position:'relative'}}>
            {this.props.inmate.photoID && this.props.inmate.photoID.length > 0 ? <Icon name="user" style={{color:this.props.inmate.sex == "M" ? "#89B0E0" : this.props.inmate.sex == "F" ? "#EF7D92" : "#bbb"}} /> : <Icon name="circle" style={{color:this.props.inmate.sex == "M" ? "#89B0E0" : this.props.inmate.sex == "F" ? "#EF7D92" : "#bbb"}} />}
            {this.props.inmate.pendingAttendance ? <div className='inmate-pending-attendance' /> : null}
          </Table.Cell>
          <Table.Cell>
            {this.props.mode == 'dual' ? <Popup
                hideOnScroll
                on='click'
                trigger={<div className='link' style={{whiteSpace:'normal'}}>
                  {this.props.inmate.lastName}, {this.props.inmate.firstName}{this.props.inmate.mi ? ` ${this.props.inmate.mi.charAt(0)}` : null}
                  {this.props.inmate.favorite ? <Popup content="Inmate is a favorite" position="top center" trigger={<Icon name="star" color="blue" style={{marginLeft:5}} />} /> : null}
                </div>}
            >
                <Popup.Header>Open in pane</Popup.Header>
                <Popup.Content>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(1, this.props.inmate.recordID) }}>1</Label>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(2, this.props.inmate.recordID) }}>2</Label>
                  {this.props.inmate.favorite ? <Label circular icon='star' className='zero' onClick={() => { this.props.unfavoriteInmateRow(this.props.inmate.recordID) }} /> : <Label circular icon='star outline' className='zero' onClick={() => { this.props.favoriteInmateRow(this.props.inmate.recordID) }} />}
                </Popup.Content>
            </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, this.props.inmate.recordID) }}>{this.props.inmate.lastName}, {this.props.inmate.firstName}{this.props.inmate.mi ? ` ${this.props.inmate.mi.charAt(0)}` : null}</div>}
          </Table.Cell>
          <Table.Cell>{this.props.inmate.sid}</Table.Cell>
          <Table.Cell>{this.props.inmate.facilityAbbr}</Table.Cell>
          <Table.Cell>{this.props.inmate.age > 0 ? this.props.inmate.age < 22 ? <b>{this.props.inmate.age}</b> : this.props.inmate.age : ""}</Table.Cell>
          <Table.Cell>
            <Form.Field
              control={Select}
              options={[{key:0,value:null,text:''}].concat(_.filter(this.props.housing, (h:any) => h.facility == this.props.inmate.facilityID))}
              value={this.props.inmate.housingID}
              onChange={(e:any, data:any) => this.props.housingSelect(this.props.inmate, data.value)}
              style={{minWidth:150}}
              className='transparent compact'
              search
            />
          </Table.Cell>
          <Table.Cell style={{textAlign:'center'}}>{this.props.inmate.hsInfoVerified == "Y" ? <Popup content="HS Info Verified" position="top center" trigger={<Icon name="building" />} /> : <Icon />}</Table.Cell>
          <Table.Cell style={{textAlign:'center'}}>{this.props.inmate.diplomaGEDVerified == "Y" ? <Popup content="Diploma / HSE Verified" position="top center" trigger={<Icon name="graduation" />} /> : <Icon />}</Table.Cell>
          {window.location.host.split(".")[0] == "sms" ? <Table.Cell style={{textAlign:'center'}}>{this.props.inmate.abeMinutes == null || this.props.inmate.abeMinutes == 0 ? <Icon /> : <Popup content={`${Math.round(this.props.inmate.abeMinutes/60*100) / 100} TABE Hours`} position="top center" trigger={<i className="icon icon-t" style={{color: this.props.inmate.abeMinutes < 30*60 ? "black" : this.props.inmate.abeMinutes < 45*60 ? "#2185d0" : this.props.inmate.abeMinutes < 60*60 ? "#21ba45" : "#db2828"}} />} />}</Table.Cell> : null}
          {window.location.host.split(".")[0] == "sms" ? <Table.Cell style={{textAlign:'center'}}>{this.props.inmate.ged == null ? this.props.inmate.preGED == null ? <Icon /> : this.props.inmate.preGEDPassed ? <Popup content="Ready for GED" position="top center" trigger={<i className="icon icon-g" style={{color:"#21ba45"}} />} /> : <Popup content="Not Ready for GED" position="top center" trigger={<i className="icon icon-g" style={{color:"#2185d0"}} />} /> : this.props.inmate.gedPassed ? <Popup content="GED Passed" position="top center" trigger={<i className="icon icon-g" />} /> : <Popup content="GED Failed" position="top center" trigger={<i className="icon icon-g" style={{color:"#db2828"}} />} />}</Table.Cell> : null}
          {window.location.host.split(".")[0] == "sms" ? <Table.Cell style={{textAlign:'center'}}>{this.props.inmate.hiset == null ? <Icon /> : this.props.inmate.hisetPassed ? <Popup content="HiSET Passed" position="top center" trigger={<i className="icon icon-h" />} /> : <Popup content="HiSET Failed" position="top center" trigger={<i className="icon icon-h" style={{color:"#db2828"}} />} />}</Table.Cell> : null}
          {window.location.host.split(".")[0] == "sms" ? <Table.Cell style={{textAlign:'center'}}>{this.props.inmate.riasec == null ? <Icon /> : <Popup content="RIASEC Taken" position="top center" trigger={<i className="icon icon-r" />} />}</Table.Cell> : null}
          {window.location.host.split(".")[0] == "sms" ? <Table.Cell style={{textAlign:'center'}}>{!this.props.inmate.educationRefusal && !this.props.inmate.placementRefusal ? <Icon /> : new Date(this.props.inmate.educationRefusal) > new Date(this.props.inmate.placementRefusal) ? <Popup content={`Refused to Participate on ${this.props.inmate.educationRefusal}`} position="top center" trigger={<Icon name="ban" />} /> : <Popup content={`Refused Placement Testing on ${this.props.inmate.placementRefusal}`} position="top center" trigger={<Icon name="ban" color="grey" />} />}</Table.Cell> : null}
          {window.location.host.split(".")[0] == "sms" ? <Table.Cell style={{textAlign:'center'}}>{this.props.inmate.status != 'G' ? <Icon /> : <Popup content={`In Pretrial`} position="top center" trigger={<Icon name="gavel" />} />}</Table.Cell> : null}
          {window.location.host.split(".")[0] == "sms" ? <Table.Cell style={{textAlign:'center'}}>{!this.props.inmate.flagged ? <Icon /> : <Popup content={this.props.inmate.flagged} position="top center" trigger={<Icon name="warning sign" />} />}</Table.Cell> : null}
        </Table.Row>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, housing: state.drawer.options.housing, tab: state.drawer.tab, mode: state.panes.mode } },
  { ...DrawerStore.actionCreators, ...PanesStore.actionCreators, ...InmatesStore.actionCreators }
)(InmateRow as any)
