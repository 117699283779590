//import './wdyr'
import * as React from 'react'
import ReactDOM from "react-dom/client"
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store, history } from './store/configureStore'
import App from './App'
import { toast } from 'react-toastify'
import { unregister } from './registerServiceWorker'
//import registerServiceWorker from './registerServiceWorker'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <Provider store={store}>
        <HistoryRouter history={history}>
            <App />
        </HistoryRouter>
    </Provider>
)

unregister()

Date.prototype.today = function () { 
    return (((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+ this.getFullYear();
}

// For the time now
Date.prototype.timeNow = function () {
     return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
}

String.prototype.validate = function() {
    var validated = ((this.match(/[0-9a-zA-Z\,\.\-\"\_\'\)\(\;\=\:\*\!\?\$\[\]\+\&\%\@\#\~\/\\ ]+/) || []).pop() || '')
    if (this.length != validated.length) {
        toast.error(`Special character not allowed: ${this[validated.length]}`)
    }
    return validated
}

String.prototype.validateNum = function() {
    var validated = ((this.match(/[0-9]+/) || []).pop() || '')
    if (this.length != validated.length) {
        toast.error(`Special character not allowed: ${this[validated.length]}`)
    }
    return validated
}

String.prototype.validateCode = function() {
    var validated = ((this.match(/[A-Za-z]+/) || []).pop() || '')
    if (this.length != validated.length) {
        toast.error(`Character not allowed: ${this[validated.length]}`)
    }
    validated = validated.slice(0, 3).toUpperCase();
    return validated
}

String.prototype.validateName = function() {
    var validated = ((this.match(/[0-9a-zA-Z\-\' ]+/) || []).pop() || '')
    if (this.length != validated.length) {
        toast.error(`Special character not allowed: ${this[validated.length]}`)
    }
    return validated.replace(/ +/g, ' ')
}

String.prototype.validateNick = function() {
    var validated = ((this.match(/[0-9a-zA-Z\-\'\. ]+/) || []).pop() || '')
    if (this.length != validated.length) {
        toast.error(`Special character not allowed: ${this[validated.length]}`)
    }
    return validated.replace(/ +/g, ' ')
}

String.prototype.validateEmail = function() {
    var validated = ((this.match(/[0-9a-zA-Z\@\.\!\#\$\%\&\'\*\+\-\/\=\?\^\_]+/) || []).pop() || '')
    if (this.length != validated.length) {
        toast.error(`Special character not allowed: ${this[validated.length]}`)
    }
    return validated
}

String.prototype.validatePhone = function() {
    var validated = ((this.match(/\+?[0-9\-\(\) ]*/) || []).pop() || '')
    if (this.length != validated.length) {
        toast.error(`Special character not allowed: ${this[validated.length]}`)
    }
    return validated
}

String.prototype.validateAccess = function() {
    var validated = ((this.match(/[0-9a-zA-Z\-\(\) ]+/) || []).pop() || '')
    if (this.length != validated.length) {
        toast.error(`Special character not allowed: ${this[validated.length]}`)
    }
    return validated.replace(/ +/g, ' ')
}

String.prototype.validatePath = function() {
    var validated = ((this.match(/[0-9a-zA-Z\,\.\-\_\'\)\(\;\=\!\$\[\]\+\&\%\@\#\~ ]+/) || []).pop() || '')
    if (this.length != validated.length) {
        toast.error(`Special character not allowed: ${this[validated.length]}`)
    }
    return validated
}

String.prototype.validateSSN = function() {
    var validated = ((this.match(/[0-9a-zA-Z\-]+/) || []).pop() || '')
    if (this.length != validated.length) {
        toast.error(`Special character not allowed: ${this[validated.length]}`)
    }
    return validated
}

String.prototype.validateSS = function() {
    var num = parseInt(this.toString())
    if (isNaN(num)) {
        return ""
    } else {
        if (num < 0) {
            return "0"
        } else if (num > 999) {
            return "999"
        } else {
            return num.toString()
        }
    }
}

String.prototype.validateHiSET = function() {
    var num = parseInt(this.toString())
    if (isNaN(num)) {
        return ""
    } else {
        if (num < 1) {
            return "1"
        } else if (num > 20) {
            return "20"
        } else {
            return num.toString()
        }
    }
}

String.prototype.validateRIASEC = function() {
    var num = parseInt(this.toString())
    if (isNaN(num)) {
        return ""
    } else {
        if (num < 0) {
            return "0"
        } else if (num > 7) {
            return "7"
        } else {
            return num.toString()
        }
    }
}