import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import Cleave from "cleave.js/react"
import {
  Container,
  Icon,
  Search,
  Button,
  Grid,
  Header,
  Tab,
  Label,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type PaneProps =
    { career: PanesStore.Career, num: 1 | 2, print: boolean, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class PathwayPane extends React.PureComponent<PaneProps> {
    public render() {
        return (
          <Container fluid>
            {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
              <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
            </div>}
            <Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em',marginTop:15}} />
            <Grid style={{marginBottom:10}}>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                  <Header as='h2' style={{display:'inline-block',marginTop:0,width:750,whiteSpace:'normal'}}><div><Icon name='suitcase' /> {this.props.career.data.name}</div></Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div style={{marginTop:20,marginBottom:10,whiteSpace:'normal'}}>{this.props.career.data.narrative}</div>
            <Tab menu={{ secondary: true, pointing: true }} panes={[
              { menuItem: 'Assigned', render: () => <Fragment>
                <Search
                  loading={this.props.career.inmate.loading}
                  onResultSelect={(e:any, data:any) => this.props.selectCareerInmate(this.props.num, data.result)}
                  onSearchChange={(e:any, data:any) => this.props.searchCareers(this.props.num, data.value as string)}
                  results={this.props.career.options.inmates}
                  value={this.props.career.inmate.value}
                  style={{display:'inline-block'}}
                />
                <div className='career-labels' style={{marginTop:10,whiteSpace:'normal'}}>
                  {_.map(this.props.career.info.inmates, (inmate:any, i:number) => <Label key={i} style={{margin:0,marginRight:4,marginBottom:4}}>
                    {this.props.mode == 'dual' ? <Popup
                      hideOnScroll
                      on='click'
                      trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block',color:'rgba(0, 0, 0, 0.6)'}}>{inmate.name}</div>}
                    >
                      <Popup.Header>Open in pane</Popup.Header>
                      <Popup.Content>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, inmate.recordID) }}>1</Label>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, inmate.recordID) }}>2</Label>
                      </Popup.Content>
                    </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block',color:'rgba(0, 0, 0, 0.6)'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, inmate.recordID) }}>{inmate.name}</div>}
                    <Icon name='delete' onClick={() => this.props.removeCareerInmate(this.props.num, inmate.recordID)} />
                  </Label>)}
                </div>
                {this.props.print ? null : <Button positive content='Save' onClick={_.debounce(() => this.props.saveCareer(this.props.num, 'pathway'), 10000, true)} style={{marginTop:20}} />}
              </Fragment> },
              { menuItem: 'Recommended', render: () => <Fragment>
                <div className='career-labels' style={{marginTop:10,whiteSpace:'normal'}}>
                  {this.props.career.data.recommendations.map((inmate:any, i:number) => <Label key={i} style={{margin:0,marginRight:4,marginBottom:4}}>
                    {this.props.mode == 'dual' ? <Popup
                      hideOnScroll
                      on='click'
                      trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block',color:'rgba(0, 0, 0, 0.6)'}}>{inmate.name}</div>}
                    >
                      <Popup.Header>Open in pane</Popup.Header>
                      <Popup.Content>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, inmate.recordID) }}>1</Label>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, inmate.recordID) }}>2</Label>
                      </Popup.Content>
                    </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block',color:'rgba(0, 0, 0, 0.6)'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, inmate.recordID) }}></div>}
                    {/*<Icon name='delete' />*/}
                  </Label>)}
                </div>
              </Fragment> },
              { menuItem: 'Classes', render: () => <div style={{whiteSpace:'normal'}}>
                {this.props.career.data.templates.map((template:any, t:number) => <Label key={t} color={template.status == 'active' ? 'green' : template.status == 'scheduled' ? 'yellow' : 'red'} style={{margin:0,marginRight:4,marginBottom:4}}>
                  {this.props.mode == 'dual' ? <Popup
                    hideOnScroll
                    on='click'
                    trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block',color:'white'}}>{template.name}</div>}
                  >
                    <Popup.Header>Open in pane</Popup.Header>
                    <Popup.Content>
                      <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                      <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                    </Popup.Content>
                  </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block',color:'white'}} onClick={() => { this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}></div>}
                  {/*<Icon name='delete' />*/}
                </Label>)}
              </div> },
            ]} /> 
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { career: ownProps.career, num: ownProps.num, print: ownProps.options.print, mode: state.panes.mode } },
  PanesStore.actionCreators
)(PathwayPane as any)