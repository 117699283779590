import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as ClassesStore from '../../store/reducers/classes'
import Dashboard from './Dashboard'
import Profile from './Profile'
import UniSearch from './Search'
import Inmates from './Inmates'
import TABE from './TABE'
import GED from './GED'
import HiSET from './HiSET'
import RIASEC from './RIASEC'
import ANDs from './ANDs'
import UAs from './UAs'
import Classes from './Classes'
import Templates from './Templates'
import Certificates from './Certificates'
import Schedules from './Schedules'
import Reports from './Reports'
import Admin from './Admin'
import Support from './Support'
import Careers from './Careers'
import CreateClassInfo from '../classes/CreateClassInfo'
import CreateClassOptions from '../classes/CreateClassOptions'
import CreateClassSchedule from '../classes/CreateClassSchedule'
import {
  Button,
  Header,
  Modal,
  Search,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type DrawerProps =
    { surveys: any, staff: any } &
    DrawerStore.DrawerState &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof ClassesStore.actionCreators &
    WithRouterProps<{}>

class Drawer extends React.PureComponent<DrawerProps> {
    public componentDidMount() {
      //this.props.fetchData()
    }

    public render() {
      const panes = [
        { menuItem: 'Info', render: () => <Tab.Pane><CreateClassInfo /></Tab.Pane> },
        { menuItem: 'Options', render: () => <Tab.Pane><CreateClassOptions /></Tab.Pane> },
        { menuItem: 'Schedule', render: () => <Tab.Pane><CreateClassSchedule /></Tab.Pane> }
      ]

      return (
        <Fragment>
          <div id='dimmer' className={`${this.props.dimmer ? '' : 'hidden'} ${this.props.open ? 'active' : ''}`} onClick={() => this.props.toggleDrawer(this.props.tab)} />
          <div id='drawer' className={`${this.props.open ? 'open' : 'closed'} ${this.props.surveys.data.length > 0 ? 'surveys' : ''}`} style={{backgroundColor:(this.props.staff.theme ? this.props.staff.theme.drawerBackgroundColor : false)}}>
            <div className='content'>
              {this.props.tab == 'dashboard' ? <Dashboard /> : null}
              {this.props.tab == 'search' ? <UniSearch /> : null}
              {this.props.tab == 'profile' ? <Profile /> : null}
              {this.props.tab == 'inmates' ? <Inmates /> : null}
              {this.props.tab == 'tabe' ? <TABE /> : null}
              {this.props.tab == 'ged' ? <GED /> : null}
              {this.props.tab == 'hiset' ? <HiSET /> : null}
              {this.props.tab == 'riasec' ? <RIASEC /> : null}
              {this.props.tab == 'ands' ? <ANDs /> : null}
              {this.props.tab == 'uas' ? <UAs /> : null}
              {this.props.tab == 'classes' ? <Classes /> : null}
              {this.props.tab == 'templates' ? <Templates /> : null}
              {this.props.tab == 'certificates' ? <Certificates /> : null}
              {this.props.tab == 'schedules' ? <Schedules /> : null}
              {this.props.tab == 'reports' ? <Reports /> : null}
              {this.props.tab == 'admin' ? <Admin /> : null}
              {this.props.tab == 'support' ? <Support /> : null}
              {this.props.tab == 'careers' ? <Careers /> : null}
            </div>
          </div>
          <Modal
            open={this.props.createClassM.open}
            onClose={this.props.toggleCreateClass}
            centered={false}
            className='create-class'
          >
            <Modal.Header content='Create Class' />
            <Modal.Content>
              <Search
                placeholder='Class template'
                loading={this.props.createClassM.loading}
                onResultSelect={(e:any, data:any) => this.props.selectTemplate(data.result)}
                onSearchChange={(e:any, data:any) => this.props.searchTemplates(data.value as string)}
                results={this.props.createClassM.options.templates}
                value={this.props.createClassM.template.value}
                onFocus={this.props.clearTemplates}
                style={{display:'inline-block'}}
                category
              />
              {this.props.createClassM.template.id == 0 ? null : <Header as='h3' style={{display:'inline-block',marginTop:0,marginLeft:20}}>{this.props.createClassM.template.category} -- {this.props.createClassM.template.value}</Header>}
              {this.props.createClassM.template.id == 0 ? null : <Tab menu={{ secondary: true, pointing: true }} panes={panes} style={{marginTop:10}} />}
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleCreateClass}>Close</Button>
              <Button color='green' onClick={_.debounce(this.props.createClass, 10000, true)}>Create</Button>
            </Modal.Actions>
          </Modal>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.drawer, staff: state.staff.staff, surveys: state.dashboard.surveys } },
  { ...DrawerStore.actionCreators, ...InmatesStore.actionCreators, ...ClassesStore.actionCreators }
)(Drawer as any)