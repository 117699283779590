import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../withRouter'
import { ApplicationState } from '../store'
import { history } from '../store/configureStore'
import * as PanesStore from '../store/reducers/panes'
import InmatePane from './inmates/InmatePane'
import ClassPane from './classes/ClassPane'
import ProgramPane from './classes/ProgramPane'
import CoursePane from './classes/CoursePane'
import TemplatePane from './classes/TemplatePane'
import StaffPane from './admin/StaffPane'
import FacilityPane from './admin/FacilityPane'
import CareerPane from './admin/CareerPane'
import PathwayPane from './admin/PathwayPane'

type PaneProps =
    { num: 1 | 2, animated: boolean, staff: any, notifications: any, surveys: any, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class Pane extends React.PureComponent<PaneProps> {
    unlisten: any

    public componentDidMount() {
      this.props.loadPane(this.props.num)
      this.unlisten = history.listen(listener => {
        if (listener.action == "POP") {
          this.props.loadPane(this.props.num)
        }
      });
    }

    public componentWillUnmount() {
      this.unlisten()
    }

    public render() {
      var archived = false
      if (this.props.panes[this.props.num].type == 'inmate' && !this.props.panes[this.props.num].inmate.data.active) { archived = true }
      if (this.props.panes[this.props.num].type == 'class' && !this.props.panes[this.props.num].class.data.active) { archived = true }
      if (this.props.panes[this.props.num].type == 'program' && !this.props.panes[this.props.num].program.data.active) { archived = true }
      if (this.props.panes[this.props.num].type == 'course' && !this.props.panes[this.props.num].course.data.active) { archived = true }
      if (this.props.panes[this.props.num].type == 'staff' && this.props.panes[this.props.num].staff.info.deleted) { archived = true }
      if (this.props.panes[this.props.num].type == 'facility' && this.props.panes[this.props.num].facility.info.deleted) { archived = true }
      if (this.props.panes[this.props.num].type == 'template' && !this.props.panes[this.props.num].template.data.active) { archived = true }
      //if (this.props.panes[this.props.num].type == 'career' && !this.props.panes[this.props.num].career.data.active) { archived = true }
      //if (this.props.panes[this.props.num].type == 'pathway' && !this.props.panes[this.props.num].career.data.active) { archived = true }
      return (
        <div id={`pane-${this.props.num}`} className={`pane ${this.props.panes[this.props.num].options.print ? 'print' : ''}`} style={{top: 45 + (this.props.print ? 0 : (this.props.notifications.list.length > 0 ? 37 : 0) + (this.props.surveys.data.length > 0 ? 29 : 0)), width: this.props.panes[this.props.num].open ? ((this.props.num == 1 && this.props.mode == 'left') || (this.props.num == 2 && this.props.mode == 'right') ? '100%' : (this.props.num == 2 && this.props.mode == 'left') || (this.props.num == 1 && this.props.mode == 'right') ? '0%' : '50%') : '0%', backgroundImage: archived ? 'url(/assets/archive.png)' : undefined}} onClick={() => this.props.toggleAnimated(this.props.num)}>
          <div id={`pane-${this.props.num}-content`} className={`content-pane ${this.props.panes[this.props.num].animated ? 'animated' : ''}`} style={{opacity:this.props.panes[this.props.num].open?1:0,backgroundColor:(this.props.staff.theme ? this.props.staff.theme.paneBackgroundColor : false)}}>
            <div className='content'>
              {this.props.panes[this.props.num].type == 'inmate' ? <InmatePane num={this.props.num} inmate={this.props.panes[this.props.num].inmate} options={this.props.panes[this.props.num].options} /> : null}
              {this.props.panes[this.props.num].type == 'class' ? <ClassPane num={this.props.num} class={this.props.panes[this.props.num].class} options={this.props.panes[this.props.num].options} /> : null}
              {this.props.panes[this.props.num].type == 'program' ? <ProgramPane num={this.props.num} program={this.props.panes[this.props.num].program} options={this.props.panes[this.props.num].options} /> : null}
              {this.props.panes[this.props.num].type == 'course' ? <CoursePane num={this.props.num} course={this.props.panes[this.props.num].course} options={this.props.panes[this.props.num].options} /> : null}
              {this.props.panes[this.props.num].type == 'template' ? <TemplatePane num={this.props.num} template={this.props.panes[this.props.num].template} options={this.props.panes[this.props.num].options} /> : null}
              {this.props.panes[this.props.num].type == 'staff' ? <StaffPane num={this.props.num} staff={this.props.panes[this.props.num].staff} options={this.props.panes[this.props.num].options} /> : null}
              {this.props.panes[this.props.num].type == 'facility' ? <FacilityPane num={this.props.num} facility={this.props.panes[this.props.num].facility} options={this.props.panes[this.props.num].options} /> : null}
              {this.props.panes[this.props.num].type == 'career' ? <CareerPane num={this.props.num} career={this.props.panes[this.props.num].career} options={this.props.panes[this.props.num].options} /> : null}
              {this.props.panes[this.props.num].type == 'pathway' ? <PathwayPane num={this.props.num} career={this.props.panes[this.props.num].career} options={this.props.panes[this.props.num].options} /> : null}
            </div>
          </div>
        </div>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { ...state.panes, num: ownProps.num, staff: state.staff.staff, notifications: state.dashboard.notifications, surveys: state.dashboard.surveys, print: state.panes.panes[ownProps.num].options.print } },
  PanesStore.actionCreators
)(Pane as any)
