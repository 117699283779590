import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Label,
  Accordion,
  Tab,
  Select,
  Button,
  TextArea,
  Header,
  Divider,
  Message,
  Icon,
  Popup,
  Table,
  Modal
} from 'semantic-ui-react'
import TemplatePane from '../classes/TemplatePane'
const _ = require('underscore')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateCareers extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchInmateCareers(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      const sexOpts = [{ key:0,value:'M',text:'Male' },{ key:1,value:'F',text:'Female' }]
      const raceOpts = [{ key:0,value:'I',text:'American Indian or Alaskan Native' },{ key:1,value:'A',text:'Asian' },{ key:2,value:'B',text:'Black or African-American' },{ key:3,value:'P',text:'Hawaiian or Pacific Islander' },{ key:4,value:'H',text:'Hispanic' },{ key:5,value:'W',text:'White' },{ key:6,value:'U',text:'Other or Unknown' }]
      const facilityOpts = [{ key:0,value:'AZSC',text:'AZSC' },{ key:1,value:'HCF',text:'HCF' },{ key:2,value:'HCCC',text:'HCCC' },{ key:3,value:'KCCC',text:'KCCC' },{ key:4,value:'KCF',text:'KCF' },{ key:5,value:'MCCC',text:'MCCC' },{ key:6,value:'OCCC',text:'OCCC' },{ key:7,value:'OOS',text:'OOS' },{ key:8,value:'WCF',text:'WCF' },{ key:9,value:'WCCC',text:'WCCC' }]

      const displayTrait = (trait:any) => {
          var display = ''
          switch (trait.field) {
          case 'dob':
            if (trait.startDate == trait.endDate) {
                return `DoB: ${trait.startDate}`
            } else {
                return `DoB: ${trait.startDate} - ${trait.endDate}`
            }
          case 'age':
              if (trait.min == trait.max) {
                  return `Age: ${trait.min}`
              } else {
                  return `Ages: ${trait.min} - ${trait.max}`
              }
          case 'sex':
              var values = _.map(trait.value, (key:string) => _.find(sexOpts, (opt:any) => opt.value == key).text)
              if (values.length > 1) {
                  return `Sexes: ${values.join(', ')}`
              } else {
                  return `Sex: ${values.join(', ')}`
              }
          case 'race':
              var values = _.map(trait.value, (key:string) => _.find(raceOpts, (opt:any) => opt.value == key).text)
              if (values.length > 1) {
                  return `Races: ${values.join(', ')}`
              } else {
                  return `Race: ${values.join(', ')}`
              }
          case 'facility':
              var values = _.map(trait.value, (key:string) => _.find(facilityOpts, (opt:any) => opt.value == key).text)
              if (values.length > 1) {
                  return `Facilities: ${values.join(', ')}`
              } else {
                  return `Facility: ${values.join(', ')}`
              }
          }
          return display
      }

      const careerPanels = this.props.inmate.careers.data.map((career:any) => {
        return { key: career.recordID, title: { content:
          <Fragment>
            {career.name}
          </Fragment>
        }, content: { content:
          <Fragment>
            <div style={{whiteSpace:'normal',marginBottom:10}}>{career.narrative}</div>
            <Tab menu={{ secondary: true, pointing: true }} panes={[
              { menuItem: 'Prerequisites', render: () => <Fragment>
                <div style={{whiteSpace:'normal'}}>{_.filter(career.templates, (template:any) => template.pre).map((template:any, t:number) => <Label color={template.status == 'completed' ? 'green' : template.status == 'scheduled' ? 'yellow' : template.status == 'active' ? undefined : 'red'} key={t} style={{margin:0,marginRight:4,marginBottom:4}}>
                  {this.props.mode == 'dual' ? <Popup
                    hideOnScroll
                    on='click'
                    trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block',color:(template.status == 'active' ? 'rgba(0, 0, 0, 0.6)' : 'white')}}>{template.name}</div>}
                  >
                    <Popup.Header>Open in pane</Popup.Header>
                    <Popup.Content>
                      <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                      <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                    </Popup.Content>
                  </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block',color:(template.status == 'active' ? 'rgba(0, 0, 0, 0.6)' : 'white')}} onClick={() => { this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}>{template.name}</div>}
                </Label>)}</div>
                <div style={{whiteSpace:'normal'}}>{(career.traits ?? []).map((trait:any, t:number) => <Label color={trait.fulfilled ? 'green' : undefined} key={t} style={{margin:0,marginRight:4,marginBottom:4}}>{displayTrait(trait)}</Label>)}</div>
              </Fragment> },
              { menuItem: 'Path Progress', render: () => <div style={{whiteSpace:'normal'}}>{_.filter(career.templates, (template:any) => !template.pre).map((template:any, t:number) => <Label color={template.status == 'completed' ? 'green' : template.status == 'scheduled' ? 'yellow' : template.status == 'active' ? undefined : 'red'} key={t} style={{margin:0,marginRight:4,marginBottom:4}}>
                {this.props.mode == 'dual' ? <Popup
                  hideOnScroll
                  on='click'
                  trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block',color:(template.status == 'active' ? 'rgba(0, 0, 0, 0.6)' : 'white')}}>{template.name}</div>}
                >
                  <Popup.Header>Open in pane</Popup.Header>
                  <Popup.Content>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                  </Popup.Content>
                </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block',color:(template.status == 'active' ? 'rgba(0, 0, 0, 0.6)' : 'white')}} onClick={() => { this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}>{template.name}</div>}
              </Label>)}</div> },
            ]} /> 
          </Fragment>
        }}
      })

      return (
        <Container fluid className='full-accord'>
          <Header as='h3' style={{marginTop:10,display:'inline-block'}}>Career Pathways</Header>
          <Tab menu={{ secondary: true, pointing: true }} panes={[
            { menuItem: 'Assigned', render: () => this.props.inmate.careers.data.length > 0 ? <Accordion styled panels={careerPanels}/> : null },
            { menuItem: 'Recommended', render: () => <Fragment>
              <div style={{whiteSpace:'normal'}}>{this.props.inmate.careers.recommendations.map((rec:any, r:number) => <Label key={r} style={{margin:0,marginRight:4,marginBottom:4}}>
                {this.props.mode == 'dual' ? <Popup
                  hideOnScroll
                  on='click'
                  trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block',color:'rgba(0, 0, 0, 0.6)'}}>{rec.name}</div>}
                >
                  <Popup.Header>Open in pane</Popup.Header>
                  <Popup.Content>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchCareer(1, rec.recordID, 'pathway') }}>1</Label>
                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchCareer(2, rec.recordID, 'pathway') }}>2</Label>
                  </Popup.Content>
                </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block',color:'rgba(0, 0, 0, 0.6)'}} onClick={() => { this.props.fetchCareer(this.props.mode == 'left' ? 1 : 2, rec.recordID, 'pathway') }}>{rec.name}</div>}
              </Label>)}</div>
            </Fragment> }
          ]} />
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print, mode: state.panes.mode } },
  PanesStore.actionCreators
)(InmateCareers as any)