import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as InmatesStore from '../../store/reducers/inmates'
import DatePicker from 'react-datepicker'
import {
  Popup,
  Form,
  Dropdown,
  Icon
} from 'semantic-ui-react'
const _ = require('underscore')

type TableProps =
    { value: string, column: string, label: string, popup: string, className: string, readOnly: boolean, onChange: any, onFork: any, flags: any, width: 1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16 } &
    InmatesStore.InmateFilters &
    typeof InmatesStore.actionCreators &
    WithRouterProps<{}>

class OTField extends React.PureComponent<TableProps> {
    public render() {
      var flag = _.find(this.props.flags, (flag:any) => flag.column == this.props.column);
      var data = _.map(_.filter(this.props.data, (data:any) => data.column == this.props.column), (data:any) => { return { key: data.recordID, value: data.value, text: data.value, description: data.date } });
      if (flag) {
        return (
          <Popup content={flag.message} position='top center' trigger={
            <div className={`error field wide ${this.props.width ?? 'sixteen'}`} style={{width:'100%'}}>
              <label>
                {this.props.label}
                {data.length > 1 ? <Dropdown trigger={<Icon name='fork' color='blue' />} options={data} value={this.props.value} onChange={this.props.onFork} className='fork' /> : null}
              </label>
              <div className='ui datepicker'>
                <DatePicker
                  name={this.props.column}
                  selected={this.props.value ? new Date(this.props.value) : undefined}
                  onChange={this.props.onChange}
                />
              </div>
            </div>
          }/>
        )
      } else {
        return (
          <div className={`field wide ${this.props.width ?? 'sixteen'}`} style={{width:'100%'}}>
            <label>
              {this.props.label}
              {data.length > 1 ? <Dropdown trigger={<Icon name='fork' color='blue' />} options={data} value={this.props.value} onChange={this.props.readOnly ? () => {} : this.props.onFork} className='fork' /> : null}
            </label>
            <div className='ui datepicker'>
              <DatePicker
                name={this.props.column}
                selected={this.props.value ? new Date(this.props.value) : undefined}
                onChange={this.props.readOnly ? () => {} : this.props.onChange}
                readOnly={this.props.readOnly}
                className={this.props.className}
              />
            </div>
          </div>
        )
      }
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { field: state.inmates.filters[ownProps.name], ...ownProps } },
  InmatesStore.actionCreators
)(OTField as any)
