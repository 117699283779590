import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import LogUpdates from './LogUpdates'
import LogActivities from './LogActivities'
import LogPDFs from './LogPDFs'
import LogOT from './LogOT'
import LogFlags from './LogFlags'
import {
  Container,
  Tab,
  Form,
  Input,
  Select
} from 'semantic-ui-react'
const _ = require('underscore')

type LogProps =
    {  } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class Logs extends React.PureComponent<LogProps> {
    public render() {
      var panes = [
        { menuItem: 'Activities', render: () => <Tab.Pane><LogActivities /></Tab.Pane> },
        { menuItem: 'Updates', render: () => <Tab.Pane><LogUpdates /></Tab.Pane> },
        { menuItem: 'PDFs', render: () => <Tab.Pane><LogPDFs /></Tab.Pane> },
        { menuItem: 'OffenderTrak', render: () => <Tab.Pane><LogOT /></Tab.Pane> },
        { menuItem: 'Flags', render: () => <Tab.Pane><LogFlags /></Tab.Pane> }
      ]

      return (
        <Container fluid>
          <Tab menu={{ secondary: true }} panes={panes} />
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return {  } },
  AdminStore.actionCreators
)(Logs as any)
