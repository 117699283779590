import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as DrawerStore from '../../store/reducers/drawer'
import * as PanesStore from '../../store/reducers/panes'
import {
  Table,
  Popup,
  Label
} from 'semantic-ui-react'

type LogProps =
    { log: any, tab: string, mode: string } &
    typeof AdminStore.actionCreators &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class LogFlagsRow extends React.PureComponent<LogProps> {
    public render() {
      return (
        <Table.Row>
          <Table.Cell>{this.props.log.recordID}</Table.Cell>
          <Table.Cell>
            {this.props.mode == 'dual' ? <Popup
              hideOnScroll
              on='click'
              trigger={<div className='link' style={{whiteSpace:'normal'}}>{this.props.log.lastName}, {this.props.log.firstName}</div>}
            >
              <Popup.Header>Open in pane</Popup.Header>
              <Popup.Content>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(1, this.props.log.inmateID) }}>1</Label>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(2, this.props.log.inmateID) }}>2</Label>
              </Popup.Content>
            </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, this.props.log.inmateID) }}>{this.props.log.lastName}, {this.props.log.firstName}</div>}
          </Table.Cell>
          <Table.Cell>{this.props.log.message}</Table.Cell>
          <Table.Cell>{this.props.log.timestamp}</Table.Cell>
          <Table.Cell>{this.props.log.fileName}</Table.Cell>
          <Table.Cell>{this.props.log.resolved ? '✓' : ''}</Table.Cell>
        </Table.Row>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { log: ownProps.log, tab: state.drawer.tab, mode: state.panes.mode } },
  { ...AdminStore.actionCreators, ...DrawerStore.actionCreators, ...PanesStore.actionCreators }
)(LogFlagsRow as any)
