import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import {
  Popup,
  Form,
  Input,
  Icon,
  Dropdown
} from 'semantic-ui-react'
const _ = require('underscore')

type TableProps =
    { num: 1|2, value: string, column: string, label: string, placeholder: string, popup: string, className: string, readOnly: boolean, onChange: any, onFork: any, panes: any, flags: any[], data: any[], width: 1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16 } &
    InmatesStore.InmateFilters &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class OTField extends React.PureComponent<TableProps> {
    public render() {
      var flag = _.find(this.props.flags, (flag:any) => flag.column == this.props.column);
      var data = _.map(_.filter(this.props.data, (data:any) => data.column == this.props.column), (data:any) => { return { key: data.recordID, value: data.value, text: data.value, description: data.date } });

      if (flag && flag.code == "DUP") {
        var merge = _.all(this.props.panes, (pane:any) => pane.open && (pane.inmate.info.recordID == this.props.panes[this.props.num].inmate.info.recordID || pane.inmate.info.recordID == flag.inmateID));
        return (
          <Popup content={<div>{flag.message}: <div className='link' style={{display:'inline-block'}} onClick={() => merge ? this.props.mergeInmate(this.props.num, flag.inmateID) : this.props.fetchInmate(this.props.num == 1 ? 2 : 1, flag.inmateID)}>{merge ? 'Merge' : 'Open'}</div></div>} position='top center' on='click' trigger={
            <div className={`field wide error ${this.props.width ?? 'sixteen'}`}>
              <label>
                {this.props.label}
                {data.length > 1 ? <Dropdown trigger={<Icon name='fork' color='blue' />} options={data} value={this.props.value} onChange={this.props.onFork} className='fork' /> : null}
              </label>
              <div className='ui input icon'>
                <input type='text' placeholder={this.props.placeholder} name={this.props.column} value={this.props.value || ''} onChange={this.props.onChange} className={this.props.className} />
                <Icon name='warning sign' />
              </div>
            </div>
          }/>
        )
      } else if (flag) {
        return (
          <Popup content={flag.message} position='top center' on='click' trigger={
            <div className={`field wide error ${this.props.width ?? 'sixteen'}`}>
              <label>
                {this.props.label}
                {data.length > 1 ? <Dropdown trigger={<Icon name='fork' color='blue' />} options={data} value={this.props.value} onChange={this.props.onFork} className='fork' /> : null}
              </label>
              <div className='ui input icon'>
                <input type='text' placeholder={this.props.placeholder} name={this.props.column} value={this.props.value || ''} onChange={this.props.onChange} className={this.props.className} />
                <Icon name='warning sign' />
              </div>
            </div>
          }/>
        )
      } else {
        return (
          <div className={`field wide ${this.props.width ?? 'sixteen'}`}>
            <label>
              {this.props.label}
              {data.length > 1 ? <Dropdown trigger={<Icon name='fork' color='blue' />} options={data} value={this.props.value} onChange={this.props.readOnly ? () => {} : this.props.onFork} className='fork' /> : null}
            </label>
            <div className='ui input'>
              <input type='text' placeholder={this.props.placeholder} name={this.props.column} value={this.props.value || ''} onChange={this.props.readOnly ? () => {} : this.props.onChange} readOnly={this.props.readOnly} className={this.props.className} />
            </div>
          </div>
        )
      }
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { panes: state.panes.panes, field: state.inmates.filters[ownProps.name], ...ownProps } },
  { ...InmatesStore.actionCreators, ...PanesStore.actionCreators }
)(OTField as any)
