import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Grid,
  Input,
  Select,
  Radio,
  Checkbox,
  TextArea,
  Button,
  Header,
  Divider,
  Icon,
  Table,
  Label,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type TestProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, hide: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateChangelog extends React.PureComponent<TestProps> {
    public componentDidMount() {
        this.props.fetchInmateChangelog(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      const border = '1px solid rgba(34,36,38,.1)'
      const header = (event:any, i:number) => i == 0 ? <Table.Row>
          <Table.HeaderCell style={{width:'30%',background:'#fcfff5',color:'#2c662d',pointerEvents:'auto',fontSize:'0.9em',fontWeight:600,paddingTop:6,paddingBottom:6,border:border,borderRight:0}}>{event.updateType == 'merge' ? <Fragment>{event.staffName} <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => this.props.fetchInmate(this.props.num == 1 ? 2 : 1, event.mergeID)}>merged</div> @ {event.timestampDisplay}</Fragment> : `${event.staffName} @ ${event.timestampDisplay}`}{['Inmates','InmateEducationData'].includes(event.table) ? <span className='link' style={{float:'right'}} onClick={() => this.props.revertChange(this.props.num, event)}><Icon name='undo alternate' color='blue' /></span> : null}</Table.HeaderCell>
          <Table.HeaderCell style={{width:'50%',borderTop:border,paddingTop:6,paddingBottom:6}}>New</Table.HeaderCell>
          <Table.HeaderCell style={{width:'20%',borderTop:border,borderRight:border,paddingTop:6,paddingBottom:6}}>Previous</Table.HeaderCell>
        </Table.Row> : <Table.Row>
          <Table.HeaderCell style={{width:'30%',background:'#fcfff5',color:'#2c662d',pointerEvents:'auto',fontSize:'0.9em',fontWeight:600,paddingTop:6,paddingBottom:6,border:border,borderRight:0}}>{event.updateType == 'merge' ? <Fragment>{event.staffName} <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => this.props.fetchInmate(this.props.num == 1 ? 2 : 1, event.mergeID)}>merged</div> @ {event.timestampDisplay}</Fragment> : `${event.staffName} @ ${event.timestampDisplay}`}{['Inmates','InmateEducationData'].includes(event.table) ? <span className='link' style={{float:'right'}} onClick={() => this.props.revertChange(this.props.num, event)}><Icon name='undo alternate' color='blue' /></span> : null}</Table.HeaderCell>
          <Table.HeaderCell style={{width:'50%',background:'transparent',borderLeft:border,paddingTop:6,paddingBottom:6}} />
          <Table.HeaderCell style={{width:'20%',background:'transparent',borderLeft:0,paddingTop:6,paddingBottom:6}} />
        </Table.Row>

      return (
        <Container fluid>
          <Header as='h3'>Changelog</Header>
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Filter'
                options={this.props.inmate.changelog.options}
                value={this.props.inmate.changelog.changes||[]}
                onChange={(e:any, data:any) => this.props.updateChanges(this.props.num, data.value)}
                renderLabel={(label:any) => ({ content: label.text == '' ? label.label : label.text })}
                width={16}
                multiple
              />
            </Form.Group>
          </Form>
          {this.props.inmate.changelog.data.map((event:any, i:number) => (
            <Fragment key={i}>
              {event.table == 'Inmates' || event.table == "InmateEducationData" ? <Table compact celled striped definition unstackable style={{fontSize:'0.9em',border:0,marginBottom:15}}>
                <Table.Header>{header(event, i)}</Table.Header>
                <Table.Body>
                  {event.updates.map((update:any, i:number) => (
                    <Table.Row key={update.recordID}>
                      <Table.Cell style={{width:'30%',textWrap:'wrap',borderLeft:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}>{update.column}</Table.Cell>
                      <Table.Cell style={{width:'50%',textWrap:'wrap',borderBottom:(i == event.updates.length-1 ? border : 0)}}>{update.updatedValue}</Table.Cell>
                      <Table.Cell style={{width:'20%',textWrap:'wrap',borderRight:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}>{update.replacedValue}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
              {event.table == 'Rosters' ? <Table compact celled striped definition unstackable style={{fontSize:'0.9em',border:0,marginBottom:15}}>
                <Table.Header>{header(event, i)}</Table.Header>
                <Table.Body>
                  {event.updates.map((update:any, i:number) => (
                    <Table.Row key={update.recordID}>
                      <Table.Cell style={{width:'30%',textWrap:'wrap',borderLeft:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}>Added to roster</Table.Cell>
                      <Table.Cell style={{width:'50%',textWrap:'wrap',borderBottom:(i == event.updates.length-1 ? border : 0)}}>{update.column}</Table.Cell>
                      <Table.Cell style={{width:'20%',textWrap:'wrap',borderRight:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}></Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
              {event.table == 'Transcripts' ? <Table compact celled striped definition unstackable style={{fontSize:'0.9em',border:0,marginBottom:15}}>
                <Table.Header>{header(event, i)}</Table.Header>
                <Table.Body>
                  {event.updates.map((update:any, i:number) => (
                    <Table.Row key={update.recordID}>
                      <Table.Cell style={{width:'30%',textWrap:'wrap',borderLeft:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}>Transcript created</Table.Cell>
                      <Table.Cell style={{width:'50%',textWrap:'wrap',borderBottom:(i == event.updates.length-1 ? border : 0)}}>{update.column}</Table.Cell>
                      <Table.Cell style={{width:'20%',textWrap:'wrap',borderRight:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}></Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
              {event.table == 'InmateDocuments' ? <Table compact celled striped definition unstackable style={{fontSize:'0.9em',border:0,marginBottom:15}}>
                <Table.Header>{header(event, i)}</Table.Header>
                <Table.Body>
                  {event.updates.map((update:any, i:number) => (
                    <Table.Row key={update.recordID}>
                      <Table.Cell style={{width:'30%',textWrap:'wrap',borderLeft:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}>Document uploaded</Table.Cell>
                      <Table.Cell style={{width:'50%',textWrap:'wrap',borderBottom:(i == event.updates.length-1 ? border : 0)}}>{update.column}</Table.Cell>
                      <Table.Cell style={{width:'20%',textWrap:'wrap',borderRight:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}></Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
              {event.table == 'InmateCertificates' ? <Table compact celled striped definition unstackable style={{fontSize:'0.9em',border:0,marginBottom:15}}>
                <Table.Header>{header(event, i)}</Table.Header>
                <Table.Body>
                  {event.updates.map((update:any, i:number) => (
                    <Table.Row key={update.recordID}>
                      <Table.Cell style={{width:'30%',textWrap:'wrap',borderLeft:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}>Certificate awarded</Table.Cell>
                      <Table.Cell style={{width:'50%',textWrap:'wrap',borderBottom:(i == event.updates.length-1 ? border : 0)}}>{update.column}</Table.Cell>
                      <Table.Cell style={{width:'20%',textWrap:'wrap',borderRight:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}></Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
              {event.table == 'Waitlists' ? <Table compact celled striped definition unstackable style={{fontSize:'0.9em',border:0,marginBottom:15}}>
                <Table.Header>{header(event, i)}</Table.Header>
                <Table.Body>
                  {event.updates.map((update:any, i:number) => (
                    <Table.Row key={update.recordID}>
                      <Table.Cell style={{width:'30%',textWrap:'wrap',borderLeft:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}>Added to waitlist</Table.Cell>
                      <Table.Cell style={{width:'50%',textWrap:'wrap',borderBottom:(i == event.updates.length-1 ? border : 0)}}>{update.column}</Table.Cell>
                      <Table.Cell style={{width:'20%',textWrap:'wrap',borderRight:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}></Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
              {event.table == 'Notes' ? <Table compact celled striped definition unstackable style={{fontSize:'0.9em',border:0,marginBottom:15}}>
                <Table.Header>{header(event, i)}</Table.Header>
                <Table.Body>
                  {event.updates.map((update:any, i:number) => (
                    <Table.Row key={update.recordID}>
                      <Table.Cell style={{width:'30%',textWrap:'wrap',borderLeft:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}>Note added</Table.Cell>
                      <Table.Cell style={{width:'50%',textWrap:'wrap',borderBottom:(i == event.updates.length-1 ? border : 0)}}>{update.column}</Table.Cell>
                      <Table.Cell style={{width:'20%',textWrap:'wrap',borderRight:border,borderBottom:(i == event.updates.length-1 ? border : 0)}}></Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table> : null}
            </Fragment>
          ))}
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print, hide: ownProps.hide } },
  PanesStore.actionCreators
)(InmateChangelog as any)
