import { Action, Reducer } from 'redux'
import { AppThunkAction } from '../'
import { toast } from 'react-toastify'
import agent from '../../agent'
import { FetchStaffFavoritesAction } from './staff'
const store = require('store2')
const _ = require('underscore')

export type Inmate = {
    recordID: number,
    facilityID: number,
    housingID: number,
    facilityAbbr: string,
    housing: string,
    firstName: string,
    lastName: string,
    mi: string,
    sid: string,
    ssn: string,
    sex: string,
    age: number,
    photoID: string,
    status: string,
    hsInfoVerified: string,
    diplomaGEDVerified: string,
    educationRefusal: string,
    placementRefusal: string,
    abeMinutes: number,
    riasec: number,
    hiset: number,
    ged: number,
    preGED: number,
    hisetPassed: boolean,
    gedPassed: boolean,
    preGEDPassed: boolean,
    housingOpts: any,
    favorite: boolean,
    pendingAttendance: boolean,
    flagged: string
}

interface ToggleFilterAction { type: 'TOGGLE_INMATES_FILTER' }
interface ToggleOrderAction { type: 'TOGGLE_INMATES_ORDER', sorts: any[] }
interface FetchInmatesAction { type: 'FETCH_INMATES', facilities: any[], inmates: Inmate[], hasMore: boolean, count: number }
interface FetchNextInmatesAction { type: 'FETCH_NEXT_INMATES', inmates: Inmate[], hasMore: boolean }
interface SetLoadingAction { type: 'SET_INMATES_LOADING', loading: boolean }
interface FetchTABEAction { type: 'FETCH_TABE_LIST', facilities: any[], inmates: any[], hasMore: boolean, count: number }
interface FetchNextTABEAction { type: 'FETCH_NEXT_TABE', inmates: any[], hasMore: boolean }
interface SetTABELoadingAction { type: 'SET_TABE_LOADING', loading: boolean }
interface FetchGEDAction { type: 'FETCH_GED_LIST', facilities: any[], inmates: any[], hasMore: boolean, count: number }
interface FetchNextGEDAction { type: 'FETCH_NEXT_GED', inmates: any[], hasMore: boolean }
interface SetGEDLoadingAction { type: 'SET_GED_LOADING', loading: boolean }
interface FetchHiSETAction { type: 'FETCH_HISET_LIST', facilities: any[], inmates: any[], hasMore: boolean, count: number }
interface FetchNextHiSETAction { type: 'FETCH_NEXT_HISET', inmates: any[], hasMore: boolean }
interface SetHiSETLoadingAction { type: 'SET_HISET_LOADING', loading: boolean }
interface FetchRIASECAction { type: 'FETCH_RIASEC_LIST', facilities: any[], inmates: any[], hasMore: boolean, count: number }
interface FetchNextRIASECAction { type: 'FETCH_NEXT_RIASEC', inmates: any[], hasMore: boolean }
interface SetRIASECLoadingAction { type: 'SET_RIASEC_LOADING', loading: boolean }
interface FetchANDsAction { type: 'FETCH_ANDS_LIST', facilities: any[], inmates: any[], hasMore: boolean, count: number }
interface FetchNextANDsAction { type: 'FETCH_NEXT_ANDS', inmates: any[], hasMore: boolean }
interface SetANDsLoadingAction { type: 'SET_ANDS_LOADING', loading: boolean }
interface FetchUAsAction { type: 'FETCH_UAS_LIST', facilities: any[], inmates: any[], hasMore: boolean, count: number }
interface FetchNextUAsAction { type: 'FETCH_NEXT_UAS', inmates: any[], hasMore: boolean }
interface SetUAsLoadingAction { type: 'SET_UAS_LOADING', loading: boolean }
interface SetFilterAction { type: 'SET_INMATE_FILTER', name: string, value: FilterValue }
interface ResetFiltersAction { type: 'RESET_INMATES_FILTER' }
interface HousingSearchAction { type: 'INMATE_HOUSING_SEARCH', recordID: number, value: string }
interface HousingSelectAction { type: 'INMATE_HOUSING_SELECT', recordID: number, value: string, id: number }
interface LoadFiltersAction { type: 'LOAD_INMATE_FILTERS', filters: any, activeFilters: any }
interface FavoriteInmateAction { type: 'FAVORITE_INMATE', recordID: number }
interface UnfavoriteInmateAction { type: 'UNFAVORITE_INMATE', recordID: number }

type KnownAction = FetchStaffFavoritesAction | FavoriteInmateAction | UnfavoriteInmateAction | LoadFiltersAction | FetchUAsAction | FetchNextUAsAction | SetUAsLoadingAction | FetchANDsAction | FetchNextANDsAction | SetANDsLoadingAction | HousingSelectAction | HousingSearchAction | ToggleFilterAction | ToggleOrderAction | FetchHiSETAction | FetchNextHiSETAction | SetHiSETLoadingAction | FetchRIASECAction | FetchNextRIASECAction | SetRIASECLoadingAction | FetchGEDAction | FetchNextGEDAction | SetGEDLoadingAction | FetchInmatesAction | FetchNextInmatesAction | SetLoadingAction | FetchTABEAction | FetchNextTABEAction | SetTABELoadingAction | SetFilterAction | ResetFiltersAction
type FilterValue = string | number | boolean | null
export type InmateFilters = { [key:string]: FilterValue }

const defaultFilters:any = {
    facility: 'my',
    searchType: 'starts',
    gender: 'B',
    active: true,
    firstName: '',
    lastName: '',
    sid: '',
    dobFrom: null,
    dobTo: null,
    education: [],
    tabeLevel:  'all',
    gedFilter: 'all',
    hisetFilter: 'all'
}

export interface InmatesState {
    open: boolean,
    filters: InmateFilters,
    activeFilters: string[],
    sorts: { column: string, dir: string }[],
    tabs: {
        inmates: {
            facilities: any[],
            loading: boolean,
            hasMore: boolean,
            page: number,
            count: number,
            data: Inmate[],
        },
        tabe: {
            facilities: any[],
            loading: boolean,
            hasMore: boolean,
            page: number,
            count: number,
            data: Inmate[],
        },
        ged: {
            facilities: any[],
            loading: boolean,
            hasMore: boolean,
            page: number,
            count: number,
            data: Inmate[],
        },
        hiset: {
            facilities: any[],
            loading: boolean,
            hasMore: boolean,
            page: number,
            count: number,
            data: Inmate[],
        },
        riasec: {
            facilities: any[],
            loading: boolean,
            hasMore: boolean,
            page: number,
            count: number,
            data: Inmate[],
        },
        ands: {
            facilities: any[],
            loading: boolean,
            hasMore: boolean,
            page: number,
            count: number,
            data: Inmate[],
        },
        uas: {
            facilities: any[],
            loading: boolean,
            hasMore: boolean,
            page: number,
            count: number,
            data: Inmate[],
        }
    }
}

var typingTimeout: any

const buildParams = (filters:InmateFilters, activeFilters: string[], sorts:any, params: URLSearchParams) => {
    for (let [key, value] of Object.entries(filters)) {
        if (value != undefined && value != null && value != '') {
            if (typeof value == 'object') {
                for (let ovalue of Object.values(value)) {
                  params.append(key, ovalue as string)
                }
            } else {
                params.append(key, value.toString())
            }
        }
    }
    _.each(sorts, (sort:any) => {
        params.append('sorts', `${sort.column}:${sort.dir}`)
    })
    _.each(activeFilters, (filter:string) => {
        //params.append('active', filter)
    })
    return params
}

export const actionCreators = {
    setLoading: (loading: boolean) => ({ type: 'SET_INMATES_LOADING', loading: loading } as SetLoadingAction),
    setTABELoading: (loading: boolean) => ({ type: 'SET_TABE_LOADING', loading: loading } as SetTABELoadingAction),
    housingSearch: (recordID: number, value: string) => ({ type: 'INMATE_HOUSING_SEARCH', recordID: recordID, value: value } as HousingSearchAction),
    housingSelect: (inmate: any, housingID: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var { error } = await agent.Inmates.saveHousing(Object.assign(_.clone(inmate), { housingID: housingID }))
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Housing saved')
        }
        dispatch({ type: 'INMATE_HOUSING_SELECT', recordID: inmate.recordID, value: housingID } as HousingSelectAction)
    },
    toggleFilters: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        var state = getState().inmates
        var filters = store(`${id}:filters:inmates`) || {}
        filters["showFilters"] = !state.open
        store(`${id}:filters:inmates`, filters)
        dispatch({ type: 'TOGGLE_INMATES_FILTER' } as ToggleFilterAction)
    },
    toggleOrder: (orderBy: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var state = getState().inmates
        let tab = getState().drawer.tab
        let sorts = state.sorts
        let sort = _.find(sorts, (sort:any) => sort.column == orderBy)
        var orderDir = 'asc'
        if (sort == null) {
            sorts.unshift({ column: orderBy, dir: 'asc' })
        } else {
            if (sort.dir == 'asc') {
                sorts = _.map(sorts, (sort:any) => sort.column == orderBy ? { column: sort.column, dir: 'desc' } : sort)
            } else {
                sorts = _.filter(sorts, (sort:any) => sort.column != orderBy)
            }
        }
        dispatch({ type: 'TOGGLE_INMATES_ORDER', sorts: sorts } as ToggleOrderAction)
        let params = new URLSearchParams()
        params.append('page', '0')
        buildParams(state.filters, state.activeFilters, sorts, params)
        switch (tab) {
        case 'inmates':
            if (document.getElementById('inmates-table')) document.getElementById('inmates-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchInmates(params)
            dispatch({ type: 'FETCH_INMATES', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchInmatesAction)
            break
        case 'tabe':
            if (document.getElementById('tabe-table')) document.getElementById('tabe-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchTABEs(params)
            dispatch({ type: 'FETCH_TABE_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchTABEAction)
            break
        case 'ged':
            if (document.getElementById('ged-table')) document.getElementById('ged-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchGEDs(params)
            dispatch({ type: 'FETCH_GED_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchGEDAction)
            break
        case 'hiset':
            if (document.getElementById('hiset-table')) document.getElementById('hiset-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchHiSETs(params)
            dispatch({ type: 'FETCH_HISET_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchHiSETAction)
            break
        case 'riasec':
            if (document.getElementById('riasec-table')) document.getElementById('riasec-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchRIASECs(params)
            dispatch({ type: 'FETCH_RIASEC_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchRIASECAction)
            break
        case 'ands':
            if (document.getElementById('ands-table')) document.getElementById('ands-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchANDs(params)
            dispatch({ type: 'FETCH_ANDS_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchANDsAction)
            break
        case 'uas':
            if (document.getElementById('uas-table')) document.getElementById('uas-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchUAs(params)
            dispatch({ type: 'FETCH_UAS_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchUAsAction)
            break
        }
    },
    setOrder: (orders: string[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var state = getState().inmates
        let tab = getState().drawer.tab
        let sorts:any = []
        _.each(orders, (order:any) => {
            let existing = _.find(state.sorts, (sort:any) => sort.column == order)
            sorts.push({ column: order, dir: (existing != null ? existing.dir : 'asc') })
        })
        dispatch({ type: 'TOGGLE_INMATES_ORDER', sorts: sorts } as ToggleOrderAction)
        let params = new URLSearchParams()
        params.append('page', '0')
        buildParams(state.filters, state.activeFilters, sorts, params)
        switch (tab) {
        case 'inmates':
            if (document.getElementById('inmates-table')) document.getElementById('inmates-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchInmates(params)
            dispatch({ type: 'FETCH_INMATES', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchInmatesAction)
            break
        case 'tabe':
            if (document.getElementById('tabe-table')) document.getElementById('tabe-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchTABEs(params)
            dispatch({ type: 'FETCH_TABE_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchTABEAction)
            break
        case 'ged':
            if (document.getElementById('ged-table')) document.getElementById('ged-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchGEDs(params)
            dispatch({ type: 'FETCH_GED_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchGEDAction)
            break
        case 'hiset':
            if (document.getElementById('hiset-table')) document.getElementById('hiset-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchHiSETs(params)
            dispatch({ type: 'FETCH_HISET_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchHiSETAction)
            break
        case 'riasec':
            if (document.getElementById('riasec-table')) document.getElementById('riasec-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchRIASECs(params)
            dispatch({ type: 'FETCH_RIASEC_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchRIASECAction)
            break
        case 'ands':
            if (document.getElementById('ands-table')) document.getElementById('ands-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchANDs(params)
            dispatch({ type: 'FETCH_ANDS_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchANDsAction)
            break
        case 'uas':
            if (document.getElementById('uas-table')) document.getElementById('uas-table')!.scrollTop = 0
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchUAs(params)
            dispatch({ type: 'FETCH_UAS_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchUAsAction)
            break
        }
    },
    resetFilters: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'RESET_INMATES_FILTER' } as ResetFiltersAction)
        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        let state = getState().inmates
        let tab = getState().drawer.tab
        let params = new URLSearchParams()
        params.append('page', '0')
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        store(`${id}:filters:inmates`, state.filters)
        store(`${id}:activeFilters:inmates`, state.activeFilters)
        switch (tab) {
        case 'inmates':
            if (document.getElementById('inmates-table')) document.getElementById('inmates-table')!.scrollTop = 0
            dispatch({ type: 'SET_INMATES_LOADING', loading: true } as SetLoadingAction)
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchInmates(params)
            dispatch({ type: 'FETCH_INMATES', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchInmatesAction)
            break
        case 'tabe':
            if (document.getElementById('tabe-table')) document.getElementById('tabe-table')!.scrollTop = 0
            dispatch({ type: 'SET_TABE_LOADING', loading: true } as SetTABELoadingAction)
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchTABEs(params)
            dispatch({ type: 'FETCH_TABE_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchTABEAction)
            break
        case 'ged':
            if (document.getElementById('ged-table')) document.getElementById('ged-table')!.scrollTop = 0
            dispatch({ type: 'SET_GED_LOADING', loading: true } as SetGEDLoadingAction)
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchGEDs(params)
            dispatch({ type: 'FETCH_GED_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchGEDAction)
            break
        case 'hiset':
            if (document.getElementById('hiset-table')) document.getElementById('hiset-table')!.scrollTop = 0
            dispatch({ type: 'SET_HISET_LOADING', loading: true } as SetHiSETLoadingAction)
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchHiSETs(params)
            dispatch({ type: 'FETCH_HISET_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchHiSETAction)
            break
        case 'riasec':
            if (document.getElementById('riasec-table')) document.getElementById('riasec-table')!.scrollTop = 0
            dispatch({ type: 'SET_RIASEC_LOADING', loading: true } as SetRIASECLoadingAction)
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchRIASECs(params)
            dispatch({ type: 'FETCH_RIASEC_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchRIASECAction)
            break
        case 'ands':
            if (document.getElementById('ands-table')) document.getElementById('ands-table')!.scrollTop = 0
            dispatch({ type: 'SET_ANDS_LOADING', loading: true } as SetANDsLoadingAction)
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchANDs(params)
            dispatch({ type: 'FETCH_ANDS_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchANDsAction)
            break
        case 'uas':
            if (document.getElementById('uas-table')) document.getElementById('uas-table')!.scrollTop = 0
            dispatch({ type: 'SET_UAS_LOADING', loading: true } as SetUAsLoadingAction)
            var { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchUAs(params)
            dispatch({ type: 'FETCH_UAS_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchUAsAction)
            break
        }
    },
    fetchInmates: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SET_INMATES_LOADING', loading: true } as SetLoadingAction)
        if (document.getElementById('inmates-table')) document.getElementById('inmates-table')!.scrollTop = 0
        let state = getState().inmates  
        let params = new URLSearchParams()
        params.append('page', '0')
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchInmates(params)
        dispatch({ type: 'FETCH_INMATES', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchInmatesAction)
    },
    fetchNextInmates: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().inmates
        let sorts = state.sorts
        dispatch({ type: 'SET_INMATES_LOADING', loading: true } as SetLoadingAction)
        let params = new URLSearchParams()
        params.append('page', (state.tabs.inmates.page+1).toString())
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        _.each(sorts, (sort:any) => {
            params.append('sorts', `${sort.column}:${sort.dir}`)
        })
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore } = await agent.Inmates.fetchInmates(params)
        dispatch({ type: 'FETCH_NEXT_INMATES', inmates: inmates, hasMore: hasMore } as FetchNextInmatesAction)
    },
    fetchTABE: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SET_TABE_LOADING', loading: true } as SetTABELoadingAction)
        if (document.getElementById('tabe-table')) document.getElementById('tabe-table')!.scrollTop = 0
        let state = getState().inmates  
        let params = new URLSearchParams()
        params.append('page', '0')
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchTABEs(params)
        dispatch({ type: 'FETCH_TABE_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchTABEAction)
    },
    fetchNextTABE: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().inmates
        dispatch({ type: 'SET_TABE_LOADING', loading: true } as SetTABELoadingAction)
        let params = new URLSearchParams()
        params.append('page', (state.tabs.tabe.page+1).toString())
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore } = await agent.Inmates.fetchTABEs(params)
        dispatch({ type: 'FETCH_NEXT_TABE', inmates: inmates, hasMore: hasMore } as FetchNextTABEAction)
    },
    fetchGED: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SET_GED_LOADING', loading: true } as SetGEDLoadingAction)
        if (document.getElementById('ged-table')) document.getElementById('ged-table')!.scrollTop = 0
        let state = getState().inmates  
        let params = new URLSearchParams()
        params.append('page', '0')
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchGEDs(params)
        dispatch({ type: 'FETCH_GED_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchGEDAction)
    },
    fetchNextGED: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().inmates
        dispatch({ type: 'SET_GED_LOADING', loading: true } as SetGEDLoadingAction)
        let params = new URLSearchParams()
        params.append('page', (state.tabs.ged.page+1).toString())
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore } = await agent.Inmates.fetchGEDs(params)
        dispatch({ type: 'FETCH_NEXT_GED', inmates: inmates, hasMore: hasMore } as FetchNextGEDAction)
    },
    fetchHiSET: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SET_HISET_LOADING', loading: true } as SetHiSETLoadingAction)
        if (document.getElementById('hiset-table')) document.getElementById('hiset-table')!.scrollTop = 0
        let state = getState().inmates  
        let params = new URLSearchParams()
        params.append('page', '0')
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchHiSETs(params)
        dispatch({ type: 'FETCH_HISET_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchHiSETAction)
    },
    fetchNextHiSET: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().inmates
        dispatch({ type: 'SET_HISET_LOADING', loading: true } as SetHiSETLoadingAction)
        let params = new URLSearchParams()
        params.append('page', (state.tabs.hiset.page+1).toString())
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore } = await agent.Inmates.fetchHiSETs(params)
        dispatch({ type: 'FETCH_NEXT_HISET', inmates: inmates, hasMore: hasMore } as FetchNextHiSETAction)
    },
    fetchRIASEC: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SET_RIASEC_LOADING', loading: true } as SetRIASECLoadingAction)
        if (document.getElementById('riasec-table')) document.getElementById('riasec-table')!.scrollTop = 0
        let state = getState().inmates  
        let params = new URLSearchParams()
        params.append('page', '0')
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchRIASECs(params)
        dispatch({ type: 'FETCH_RIASEC_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchRIASECAction)
    },
    fetchNextRIASEC: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().inmates
        dispatch({ type: 'SET_RIASEC_LOADING', loading: true } as SetRIASECLoadingAction)
        let params = new URLSearchParams()
        params.append('page', (state.tabs.riasec.page+1).toString())
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore } = await agent.Inmates.fetchRIASECs(params)
        dispatch({ type: 'FETCH_NEXT_RIASEC', inmates: inmates, hasMore: hasMore } as FetchNextRIASECAction)
    },
    fetchANDs: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SET_ANDS_LOADING', loading: true } as SetANDsLoadingAction)
        if (document.getElementById('ands-table')) document.getElementById('ands-table')!.scrollTop = 0
        let state = getState().inmates  
        let params = new URLSearchParams()
        params.append('page', '0')
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchANDs(params)
        dispatch({ type: 'FETCH_ANDS_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchANDsAction)
    },
    fetchNextANDs: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().inmates
        dispatch({ type: 'SET_ANDS_LOADING', loading: true } as SetANDsLoadingAction)
        let params = new URLSearchParams()
        params.append('page', (state.tabs.ands.page+1).toString())
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore } = await agent.Inmates.fetchANDs(params)
        dispatch({ type: 'FETCH_NEXT_ANDS', inmates: inmates, hasMore: hasMore } as FetchNextANDsAction)
    },
    fetchUAs: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SET_UAS_LOADING', loading: true } as SetUAsLoadingAction)
        if (document.getElementById('uas-table')) document.getElementById('uas-table')!.scrollTop = 0
        let state = getState().inmates  
        let params = new URLSearchParams()
        params.append('page', '0')
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchUAs(params)
        dispatch({ type: 'FETCH_UAS_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchUAsAction)
    },
    fetchNextUAs: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().inmates
        dispatch({ type: 'SET_UAS_LOADING', loading: true } as SetUAsLoadingAction)
        let params = new URLSearchParams()
        params.append('page', (state.tabs.uas.page+1).toString())
        buildParams(state.filters, state.activeFilters, state.sorts, params)
        const { inmates, hasMore } = await agent.Inmates.fetchUAs(params)
        dispatch({ type: 'FETCH_NEXT_UAS', inmates: inmates, hasMore: hasMore } as FetchNextUAsAction)
    },
    setFilter: (name:string, value:FilterValue): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        let tab = getState().drawer.tab
        dispatch({ type: 'SET_INMATE_FILTER', name: name, value: value } as SetFilterAction)
        clearTimeout(typingTimeout)
        switch (tab) {
        case 'inmates':
            typingTimeout = setTimeout(async () => {
                dispatch({ type: 'SET_INMATES_LOADING', loading: true } as SetLoadingAction)
                if (document.getElementById('inmates-table')) document.getElementById('inmates-table')!.scrollTop = 0
                let state = getState().inmates
                let params = new URLSearchParams()
                params.append('page', '0')
                buildParams(state.filters, state.activeFilters, state.sorts, params)
                store(`${id}:filters:inmates`, state.filters)
                const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchInmates(params)
                dispatch({ type: 'FETCH_INMATES', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchInmatesAction)
            }, 1500)
            break
        case 'tabe':
            typingTimeout = setTimeout(async () => {
                dispatch({ type: 'SET_TABE_LOADING', loading: true } as SetTABELoadingAction)
                if (document.getElementById('tabe-table')) document.getElementById('tabe-table')!.scrollTop = 0
                let state = getState().inmates
                let params = new URLSearchParams()
                params.append('page', '0')
                buildParams(state.filters, state.activeFilters, state.sorts, params)
                store(`${id}:filters:inmates`, state.filters)
                const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchTABEs(params)
                dispatch({ type: 'FETCH_TABE_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchTABEAction)
            }, 1500)
            break
        case 'ged':
            typingTimeout = setTimeout(async () => {
                dispatch({ type: 'SET_GED_LOADING', loading: true } as SetGEDLoadingAction)
                if (document.getElementById('ged-table')) document.getElementById('ged-table')!.scrollTop = 0
                let state = getState().inmates
                let params = new URLSearchParams()
                params.append('page', '0')
                buildParams(state.filters, state.activeFilters, state.sorts, params)
                store(`${id}:filters:inmates`, state.filters)
                const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchGEDs(params)
                dispatch({ type: 'FETCH_GED_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchGEDAction)
            }, 1500)
            break
        case 'hiset':
            typingTimeout = setTimeout(async () => {
                dispatch({ type: 'SET_HISET_LOADING', loading: true } as SetHiSETLoadingAction)
                if (document.getElementById('hiset-table')) document.getElementById('hiset-table')!.scrollTop = 0
                let state = getState().inmates
                let params = new URLSearchParams()
                params.append('page', '0')
                buildParams(state.filters, state.activeFilters, state.sorts, params)
                store(`${id}:filters:inmates`, state.filters)
                const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchHiSETs(params)
                dispatch({ type: 'FETCH_HISET_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchHiSETAction)
            }, 1500)
            break
        case 'riasec':
            typingTimeout = setTimeout(async () => {
                dispatch({ type: 'SET_RIASEC_LOADING', loading: true } as SetRIASECLoadingAction)
                if (document.getElementById('riasec-table')) document.getElementById('riasec-table')!.scrollTop = 0
                let state = getState().inmates
                let params = new URLSearchParams()
                params.append('page', '0')
                buildParams(state.filters, state.activeFilters, state.sorts, params)
                store(`${id}:filters:inmates`, state.filters)
                const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchRIASECs(params)
                dispatch({ type: 'FETCH_RIASEC_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchRIASECAction)
            }, 1500)
            break
        case 'ands':
            typingTimeout = setTimeout(async () => {
                dispatch({ type: 'SET_ANDS_LOADING', loading: true } as SetANDsLoadingAction)
                if (document.getElementById('ands-table')) document.getElementById('ands-table')!.scrollTop = 0
                let state = getState().inmates
                let params = new URLSearchParams()
                params.append('page', '0')
                buildParams(state.filters, state.activeFilters, state.sorts, params)
                store(`${id}:filters:inmates`, state.filters)
                const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchANDs(params)
                dispatch({ type: 'FETCH_ANDS_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchANDsAction)
            }, 1500)
            break
        case 'uas':
            typingTimeout = setTimeout(async () => {
                dispatch({ type: 'SET_UAS_LOADING', loading: true } as SetUAsLoadingAction)
                if (document.getElementById('uas-table')) document.getElementById('uas-table')!.scrollTop = 0
                let state = getState().inmates
                let params = new URLSearchParams()
                params.append('page', '0')
                buildParams(state.filters, state.activeFilters, state.sorts, params)
                store(`${id}:filters:inmates`, state.filters)
                const { inmates, hasMore, recordCount, facilityOpts } = await agent.Inmates.fetchUAs(params)
                dispatch({ type: 'FETCH_UAS_LIST', facilities: facilityOpts, inmates: inmates, hasMore: hasMore, count: recordCount } as FetchUAsAction)
            }, 1500)
            break
        }
    },
    loadFilters: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        let all = getState().staff.staff ? (getState().staff.staff!.facilities.includes('PSD') || getState().staff.staff!.facilities.includes('HPA')) : false
        var filters:any = {
            facility: all ? 'all' : 'my',
            searchType: 'starts',
            gender: 'B',
            active: true,
            firstName: '',
            lastName: '',
            sid: '',
            dobFrom: null,
            dobTo: null,
            education: [],
            tabeLevel:  'all',
            gedFilter: 'all',
            hisetFilter: 'all'
        }
        var stored = store(`${id}:filters:inmates`) || filters
        var storedActive = store(`${id}:activeFilters:inmates`) || []
        dispatch({ type: 'LOAD_INMATE_FILTERS', filters: stored, activeFilters: storedActive } as LoadFiltersAction)
    },
    favoriteInmateRow: (recordID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { error } = await agent.Inmates.favorite(recordID)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Student favorited')
            dispatch({ type: 'FAVORITE_INMATE', recordID: recordID } as FavoriteInmateAction)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    },
    unfavoriteInmateRow: (recordID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { error } = await agent.Inmates.unfavorite(recordID)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Student unfavorited')
            dispatch({ type: 'UNFAVORITE_INMATE', recordID: recordID } as UnfavoriteInmateAction)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    }
}

export const reducer: Reducer<InmatesState> = (state: InmatesState | undefined, incomingAction: Action): InmatesState => {
    if (state === undefined) {
        var open = true
        var filters:any = defaultFilters

        return {
            open: open,
            filters: filters,
            activeFilters: [],
            sorts: [],
            tabs: {
                inmates: {
                    loading: true,
                    hasMore: true,
                    page: 0,
                    count: 0,
                    data: [],
                    facilities: []
                },
                tabe: {
                    loading: true,
                    hasMore: true,
                    page: 0,
                    count: 0,
                    data: [],
                    facilities: []
                },
                ged: {
                    loading: true,
                    hasMore: true,
                    page: 0,
                    count: 0,
                    data: [],
                    facilities: []
                },
                hiset: {
                    loading: true,
                    hasMore: true,
                    page: 0,
                    count: 0,
                    data: [],
                    facilities: []
                },
                riasec: {
                    loading: true,
                    hasMore: true,
                    page: 0,
                    count: 0,
                    data: [],
                    facilities: []
                },
                ands: {
                    loading: true,
                    hasMore: true,
                    page: 0,
                    count: 0,
                    data: [],
                    facilities: []
                },
                uas: {
                    loading: true,
                    hasMore: true,
                    page: 0,
                    count: 0,
                    data: [],
                    facilities: []
                }
            }
        }
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'TOGGLE_INMATES_FILTER':
            return {
                ...state,
                open: !state.open
            }
        case 'TOGGLE_INMATES_ORDER':
            return {
                ...state,
                sorts: action.sorts
            }
        case 'SET_INMATES_LOADING':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    inmates: {
                        ...state.tabs.inmates,
                        loading: action.loading
                    }
                }
            }
        case 'SET_TABE_LOADING':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    tabe: {
                        ...state.tabs.tabe,
                        loading: action.loading
                    }
                }
            }
        case 'SET_GED_LOADING':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    ged: {
                        ...state.tabs.ged,
                        loading: action.loading
                    }
                }
        }
        case 'SET_HISET_LOADING':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    hiset: {
                        ...state.tabs.hiset,
                        loading: action.loading
                    }
                }
            }
        case 'SET_RIASEC_LOADING':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    riasec: {
                        ...state.tabs.riasec,
                        loading: action.loading
                    }
                }
            }
        case 'SET_ANDS_LOADING':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    ands: {
                        ...state.tabs.ands,
                        loading: action.loading
                    }
                }
            }
        case 'SET_UAS_LOADING':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    ands: {
                        ...state.tabs.ands,
                        loading: action.loading
                    }
                }
            }
        case 'RESET_INMATES_FILTER':
            var filters:any = {
                facility: 'my',
                searchType: 'starts',
                gender: 'B',
                active: true,
                firstName: '',
                lastName: '',
                sid: '',
                dobFrom: null,
                dobTo: null,
                education: [],
                tabeLevel:  'all',
                gedFilter: 'all',
                hisetFilter: 'all'
            }
            return {
                ...state,
                filters: filters,
                sorts: []
            }
        case 'SET_INMATE_FILTER':
            var exists = _.any(state.activeFilters, (filter:string) => filter == action.name)
            var removed = _.filter(state.activeFilters, (filter:string) => filter != action.name)
            var pushed = state.activeFilters.concat(action.name)
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.name]: action.value
                },
                activeFilters: defaultFilters[action.name] == action.value ? removed : (exists ? state.activeFilters : pushed)
            }
        case 'FETCH_INMATES':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    inmates: {
                        facilities: action.facilities,
                        loading: false,
                        page: 0,
                        count: action.count,
                        hasMore: action.hasMore,
                        data: action.inmates
                    }
                }
            }
        case 'INMATE_HOUSING_SEARCH':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    inmates: {
                        ...state.tabs.inmates,
                        data: _.map(state.tabs.inmates.data, (inmate:any) => inmate.recordID == action.recordID ? Object.assign(_.clone(inmate), { housing: action.value }) : inmate)
                    }
                }
            }
        case 'INMATE_HOUSING_SELECT':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    inmates: {
                        ...state.tabs.inmates,
                        data: _.map(state.tabs.inmates.data, (inmate:any) => inmate.recordID == action.recordID ? Object.assign(_.clone(inmate), { housingID: action.value }) : inmate)
                    }
                }
            }
        case 'FETCH_TABE_LIST':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    tabe: {
                        loading: false,
                        page: 0,
                        count: action.count,
                        hasMore: action.hasMore,
                        data: action.inmates,
                        facilities: action.facilities
                    }
                }
            }
        case 'FETCH_GED_LIST':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    ged: {
                        loading: false,
                        page: 0,
                        count: action.count,
                        hasMore: action.hasMore,
                        data: action.inmates,
                        facilities: action.facilities
                    }
                }
            }
        case 'FETCH_HISET_LIST':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    hiset: {
                        loading: false,
                        page: 0,
                        count: action.count,
                        hasMore: action.hasMore,
                        data: action.inmates,
                        facilities: action.facilities
                    }
                }
            }
        case 'FETCH_RIASEC_LIST':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    riasec: {
                        loading: false,
                        page: 0,
                        count: action.count,
                        hasMore: action.hasMore,
                        data: action.inmates,
                        facilities: action.facilities
                    }
                }
            }
        case 'FETCH_ANDS_LIST':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    ands: {
                        loading: false,
                        page: 0,
                        count: action.count,
                        hasMore: action.hasMore,
                        data: action.inmates,
                        facilities: action.facilities
                    }
                }
            }
        case 'FETCH_UAS_LIST':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    uas: {
                        loading: false,
                        page: 0,
                        count: action.count,
                        hasMore: action.hasMore,
                        data: action.inmates,
                        facilities: action.facilities
                    }
                }
            }
        case 'FETCH_NEXT_INMATES':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    inmates: {
                        ...state.tabs.inmates,
                        loading: false,
                        page: state.tabs.inmates.page + 1,
                        hasMore: action.hasMore,
                        data: state.tabs.inmates.data.concat(action.inmates)
                    }
                }
            }
        case 'FETCH_NEXT_TABE':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    tabe: {
                        ...state.tabs.tabe,
                        loading: false,
                        page: state.tabs.tabe.page + 1,
                        hasMore: action.hasMore,
                        data: state.tabs.tabe.data.concat(action.inmates)
                    }
                }
            }
        case 'FETCH_NEXT_GED':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    ged: {
                        ...state.tabs.ged,
                        loading: false,
                        page: state.tabs.ged.page + 1,
                        hasMore: action.hasMore,
                        data: state.tabs.ged.data.concat(action.inmates)
                    }
                }
            }
        case 'FETCH_NEXT_HISET':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    hiset: {
                        ...state.tabs.hiset,
                        loading: false,
                        page: state.tabs.hiset.page + 1,
                        hasMore: action.hasMore,
                        data: state.tabs.hiset.data.concat(action.inmates)
                    }
                }
            }
        case 'FETCH_NEXT_RIASEC':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    riasec: {
                        ...state.tabs.riasec,
                        loading: false,
                        page: state.tabs.riasec.page + 1,
                        hasMore: action.hasMore,
                        data: state.tabs.riasec.data.concat(action.inmates)
                    }
                }
            }
        case 'FETCH_NEXT_ANDS':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    ands: {
                        ...state.tabs.ands,
                        loading: false,
                        page: state.tabs.ands.page + 1,
                        hasMore: action.hasMore,
                        data: state.tabs.ands.data.concat(action.inmates)
                    }
                }
            }
        case 'FETCH_NEXT_UAS':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    uas: {
                        ...state.tabs.uas,
                        loading: false,
                        page: state.tabs.uas.page + 1,
                        hasMore: action.hasMore,
                        data: state.tabs.uas.data.concat(action.inmates)
                    }
                }
            }
        case 'LOAD_INMATE_FILTERS':
            return {
                ...state,
                open: action.filters['showFilters'] != undefined ? action.filters['showFilters'] : state.open,
                filters: action.filters,
                activeFilters: action.activeFilters
            }
        case 'FAVORITE_INMATE':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    inmates: {
                        ...state.tabs.inmates,
                        data: _.map(state.tabs.inmates.data, (inmate:any) => inmate.recordID == action.recordID ? _.clone(Object.assign(inmate, { favorite: true })) : inmate)
                    }
                }
            }
        case 'UNFAVORITE_INMATE':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    inmates: {
                        ...state.tabs.inmates,
                        data: _.map(state.tabs.inmates.data, (inmate:any) => inmate.recordID == action.recordID ? _.clone(Object.assign(inmate, { favorite: false })) : inmate)
                    }
                }
            }
        default:
            return state
    }
}
